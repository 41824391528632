import React, {useEffect, useState} from "react";
import {Button, Modal, Comment, Header, TextArea, Form} from "semantic-ui-react";
import {useFormik} from "formik"
import moment from "moment";
import {useActions} from "../redux/actions";
import * as MembersActions from "../redux/actions/members";

const NotesModal:React.FC<any>=(props)=>{
    const membersActions = useActions(MembersActions);
    const [notes, setNotes] = useState([]);
    const formik=useFormik(
        {
            initialValues:{
                notes:''
            },
            onSubmit:values => {
                props.submitNotes(values.notes)
            }
        }
    )
    useEffect(() => {
        membersActions.getNotesList({
            subscriberid : props.item.Subscriber_ID,
            patNumber : props.item.PatNum,
            practiceId : props.item.practiceId
        })
            .then((res: any) => {
                setNotes(res)
            })
    }, []);

    return (
        <Modal
            open={true}
            onClose={props.hideNotesModal}
            centered={false}
            size='tiny'
            closeIcon
        >
            <Header content='Members Notes'/>
            <Modal.Content>
                <Comment.Group>
                    {notes && notes.map((item: any) => {
                        return(
                            <Comment>
                                <Comment.Content>
                                    <Comment.Author>{item.user ? item.user.firstname + ' ' + item.user.lastname : ''}</Comment.Author>
                                    <Comment.Metadata>
                                        <div>{moment(item.notestimestamp).fromNow()}{}</div>
                                    </Comment.Metadata>
                                    <Comment.Text>{item.notes}</Comment.Text>
                                </Comment.Content>
                            </Comment>
                        )
                    })}

                </Comment.Group>
                <Form className="formStyle">
                    <label>Add Note</label>
                    <TextArea rows={6} fluid name='notes' placeholder='Notes' onChange={formik.handleChange} value={formik.values.notes}/>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={props.closeNotesModal}>
                    Cancel
                </Button>
                <Button className='ui primary button' onClick={formik.submitForm}>
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default NotesModal