import { Action, ActionType } from "../../model/actionType";
import {ContactDataReport, QualityIndexReport, PriorityStatusReport, LeadDataReport} from "../../model/reports";
import createReducer from "./createReducer";
const initialState = {
  contactDataReport: [],
  qualityIndexReport : [],
  priorityStatusReport : [],
  leadDataReport : [],
  practiceList : [],
};

export const reports = createReducer(initialState, {
  [ActionType.LOAD_CONTACT_DATA_REPORT](state: ContactDataReport, action: Action<ContactDataReport>) {
    return Object.assign({ ...state }, {contactDataReport : action.payload && action.payload.reports});
  },
  [ActionType.LOAD_QUALITY_INDEX_REPORT](state: QualityIndexReport, action: Action<QualityIndexReport>) {
    return Object.assign({ ...state }, {qualityIndexReport : action.payload && action.payload.reports});
  },
  [ActionType.LOAD_PRIORITY_STATUS_REPORT](state: PriorityStatusReport, action: Action<PriorityStatusReport>) {
    return Object.assign({ ...state }, {priorityStatusReport : action.payload && action.payload.reports});
  },
  [ActionType.LOAD_LEAD_DATA_REPORT](state: LeadDataReport, action: Action<LeadDataReport>) {
    return Object.assign({ ...state }, {leadDataReport : action.payload && action.payload.reports});
  },
  [ActionType.LOAD_LEAD_DATA_REPORT_BY_DATE](state: LeadDataReport, action: Action<LeadDataReport>) {
    const leadData = state.leadDataReport;
    leadData.leadsEntered = action.payload
    return Object.assign({ ...state }, {leadDataReport :  leadData });
  },
  [ActionType.LOAD_REGIONS](state:PriorityStatusReport,action:Action<PriorityStatusReport>){
    return Object.assign({...state},action.payload)
  },
  [ActionType.LOAD_PRACTICELIST](state:any,action:Action<any>){
    return Object.assign({...state}, {practiceList: action.payload})
  },
  [ActionType.LOAD_NEW_PATIENT_ORIGIN_REPORT](state: any, action: Action<any>) {
    return Object.assign({ ...state }, {newPatientOriginReport : action.payload});
  }
});
