
import {errorHandler} from "./auth";
import {ActionType} from "../../model/actionType";


export const getHelpById = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
  let stringifyParams;
  if(params){
    stringifyParams = '/'+JSON.stringify(params)
  }
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const resp =  await api.get("helpMark"+stringifyParams);
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};
export const getHelpList = (id:any) => async (dispatch:Function, getState:Function, api:any) => {
  // dispatch({ type: ActionType.LOADER, payload: true });
  let params = '';
  if(id) {
    params = `/${id}`;
  }
  try {
    let res = await api.get(`helpMark/help${params}`);
    dispatch({ type: ActionType.LOADER, payload: false });
    return res;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
  }
};
export const saveForm = (obj:any) => async (dispatch:Function, getState:Function, api:any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    let res = await api.post(`helpMark/help`, obj);
    dispatch({ type: ActionType.LOADER, payload: false });
    return res;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
  }
};
export const updateForm = (obj:any) => async (dispatch:Function, getState:Function, api:any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    let res = await api.put(`helpMark/help`, obj);
    dispatch({ type: ActionType.LOADER, payload: false });
    return res;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
  }
};
export const hideErrorModal = () => (dispatch:Function, getState:Function, api:any)=> {
  // dispatch({ type: CLOSE_ERROR_MESSAGE, action: null });
}
export const getHelpNotifications = (id: any) => async (dispatch: Function, getState: Function, api: any) => {

  try {
    const resp =  await api.get("helpMark/notifications/"+id);
    return resp;
  } catch (err) {
    return dispatch(errorHandler(err));
  }
};

export const updateHelpNotifications = (obj: object) => async (dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const resp =  await api.put("helpMark/notifications", obj);
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};




