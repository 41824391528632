import moment from "moment";
import { formatMinutes } from "../Common";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useActions } from "../../redux/actions";
import * as MembersActions from "../../redux/actions/members";
import { Dimmer, Header, Loader, Modal, Table } from "semantic-ui-react";

type OpenIssuesPopUpProps = {
    applicationList: string[],
    hideModal: any
};

const OpenIssuesPopUp: FC<OpenIssuesPopUpProps> = ({
    applicationList,
    hideModal
}): ReactElement => {
    const openIssuesAction = useActions(MembersActions);

    const [state, setState] = useState({
        openIssuesList: [],
        loading: true
    });

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const result: any = await openIssuesAction.getOpenIssues(applicationList);

        setState({
            ...state,
            openIssuesList: result,
            loading: false
        });
    }

    const {openIssuesList} = state;

    return(
        <Modal
            closeIcon
            open={true}
            onClose={hideModal}
            centered={true}
            size='fullscreen'
        >
            
            <Header content="App Status Tracker"/>
            <Modal.Content>
                <div className="ui card">
                    <div className="content pb0 mb10" style={{maxHeight: "75vh", overflowY: "auto"}}>
                        <Table className={'table table-striped table-hover my-0 FreezeTable'}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell content="Application"/>
                                    <Table.HeaderCell content="Down Time Start"/>
                                    <Table.HeaderCell content="What Is Down"/>
                                    <Table.HeaderCell content="What to do in mean time"/>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    state.loading && 
                                    <Dimmer active inverted>
                                        <Loader inverted>Loading</Loader>
                                    </Dimmer>
                                }
                                {
                                    openIssuesList.length > 0 ?
                                    openIssuesList.map((data: any, key: any) => 
                                        <Table.Row key={'data_' + key}>
                                            {
                                                <Table.Cell>{data.application}</Table.Cell>
                                            }
                                            {
                                                <Table.Cell>{moment(data.downTimeStart).format('D MMMM YYYY HH:mm')}</Table.Cell>
                                            }
                                            {
                                                <Table.Cell>{data.whatIsDown}</Table.Cell>
                                            }
                                            {
                                                <Table.Cell>{data.whatToDoMeanTime}</Table.Cell>
                                            }
                                        </Table.Row>
                                    ) :
                                    <Table.Row>
                                        <Table.Cell colSpan={13} className="text-center" content="No Data"/>
                                    </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Modal.Content>

        </Modal>

    );
};

export default OpenIssuesPopUp;