import * as React from 'react';


import {Icon} from 'semantic-ui-react';
import Template from "../Template";

function PageUnderConstruction() {


  return (
      <Template activeLink='PageUnderConstruction'>
        <div style={{position: 'relative'}}>
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '40%',
          }}>
            <h1 className="mt25 pt20 align-self-center"><Icon name='wrench' size='massive'/></h1>
            <h2 className="text-center p15">Page Under Construction</h2>
          </div>
        </div>
      </Template>
  );

}

export default PageUnderConstruction;
