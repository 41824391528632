import React, {useEffect, useState} from "react"
import Template from "./Template"
import {useActions} from "../redux/actions";
import * as ContactDataReportActions from "../redux/actions/reports";
import {Button, Grid} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import moment from "moment";
import HelpMark from "./help/HelpMark";

declare let google: any

const ContactDataReport: React.FC<any> = () => {
    const initialState: any = {
        fdtoday: [['Hours', 'Contacted', 'Not Contacted']],
        fdweek: [['Hours', 'Contacted', 'Not Contacted']],
        fdlweek: [['Hours', 'Contacted', 'Not Contacted']],
        fdmonth: [['Hours', 'Contacted', 'Not Contacted']],
        fdlmonth: [['Hours', 'Contacted', 'Not Contacted']],
        temp: [],
        finalappointmentdata: {},
        finalTime: ''
    }

    google.charts.load('current', {'packages': ['corechart', 'bar', 'line']});

    const contactDataReportActions = useActions(ContactDataReportActions);
    const [state, setStates] = useState(initialState);
    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        contactDataReportActions.getContactDataReport()
            .then((reports: any[]) => {
                setTime();
                let finaltdata: any = {};
                const finaldata: any = {};
                const appointmentData: any = [];
                appointmentData.push(reports[5]);
                appointmentData.push(reports[6]);
                appointmentData.push(reports[7]);
                delete reports[5];
                delete reports[6];
                delete reports[7];

                //Hourly Charts For Contacted and Not Contacted
                if (reports) {
                    reports.forEach(function (d: any) {
                        for (const chart in d) {
                            if (!d.hasOwnProperty(chart)) continue;
                            finaltdata = {};
                            for (const type in d[chart]) {
                                if (!d[chart].hasOwnProperty(type)) continue;
                                for (const value in d[chart][type]) {
                                    if (!d[chart][type].hasOwnProperty(value)) continue;
                                    const hour = new Date(d[chart][type][value]['createdAt']).getHours();
                                    if (finaltdata[hour]) {
                                        if (finaltdata[hour][type]) {
                                            finaltdata[hour][type] = parseInt(finaltdata[hour][type]) + d[chart][type][value].count;
                                        } else {
                                            finaltdata[hour][type] = parseInt(d[chart][type][value].count);
                                        }
                                    } else {
                                        finaltdata[hour] = {};
                                        if (finaltdata[hour][type]) {
                                            finaltdata[hour][type] = parseInt(finaltdata[hour][type]) + d[chart][type][value].count;
                                        } else {
                                            finaltdata[hour][type] = parseInt(d[chart][type][value].count);
                                        }

                                    }
                                }
                            }
                            finaldata[chart] = finaltdata;
                        }
                    });
                    for (const name in finaldata) {
                        if (!finaldata.hasOwnProperty(name)) continue;
                        for (let hour in finaldata[name]) {
                            if (!finaldata[name].hasOwnProperty(hour)) continue;
                            state.temp.push(hour);
                            if (finaldata[name][hour].contacted) {
                                state.temp.push(finaldata[name][hour].contacted);
                            } else {
                                state.temp.push(0);
                            }
                            if (finaldata[name][hour].notcontacted) {
                                state.temp.push(finaldata[name][hour].notcontacted);
                            } else {
                                state.temp.push(0);
                            }
                            if (name == 'tdata') {
                                state.fdtoday.push(state.temp);
                            } else if (name == 'twdata') {
                                state.fdweek.push(state.temp);
                            } else if (name == 'lwdata') {
                                state.fdlweek.push(state.temp);
                            } else if (name == 'tmdata') {
                                state.fdmonth.push(state.temp);
                            } else if (name == 'lmata') {
                                state.fdlmonth.push(state.temp);
                            }
                            state.temp = [];
                        }
                    }
                    if (state.fdtoday.length <= 1) {
                        state.fdtoday.push(['', 0, 0]);
                    }
                    if (state.fdweek.length <= 1) {
                        state.fdweek.push(['', 0, 0]);
                    }
                    if (state.fdlweek.length <= 1) {
                        state.fdlweek.push(['', 0, 0]);
                    }
                    if (state.fdmonth.length <= 1) {
                        state.fdmonth.push(['', 0, 0]);
                    }
                    if (state.fdlmonth.length <= 1) {
                        state.fdlmonth.push(['', 0, 0]);
                    }
                    google.charts.load('current', {'packages': ['corechart', 'bar', 'line']});
                    google.charts.setOnLoadCallback(drawcallresulttoday);
                    google.charts.setOnLoadCallback(drawcallresultweek);
                    google.charts.setOnLoadCallback(drawcallresultlastweek);
                    google.charts.setOnLoadCallback(drawcallresultmonth);
                    google.charts.setOnLoadCallback(drawcallresultlastmonth);
                    google.charts.setOnLoadCallback(drawcallresulttodayper);
                    google.charts.setOnLoadCallback(drawcallresultweekper);
                    google.charts.setOnLoadCallback(drawcallresultlastweekper);
                    google.charts.setOnLoadCallback(drawcallresultmonthper);
                    google.charts.setOnLoadCallback(drawcallresultlastmonthper);


                    appointmentData.forEach(function (types: any) {
                        for (const keys in types) {
                            if (!types.hasOwnProperty(keys)) continue;
                            const temp: any = {};
                            state.finalappointmentdata[keys] = [['Hour', 'Appointment']];
                            types[keys].forEach(function (obj: { createdAt: string | number | Date; sum: any; }) {
                                if (temp[new Date(obj.createdAt).getHours().toString()]) {
                                    temp[new Date(obj.createdAt).getHours().toString()] += obj.sum;
                                } else {
                                    temp[new Date(obj.createdAt).getHours().toString()] = {};
                                    temp[new Date(obj.createdAt).getHours().toString()] = obj.sum;
                                }
                            });
                            for (const i in temp) {
                                state.finalappointmentdata[keys].push([i.toString(), parseInt(temp[i])]);
                            }
                            if (state.finalappointmentdata[keys].length <= 1) {
                                state.finalappointmentdata[keys].push(['', 0]);
                            }
                        }
                    });
                    google.charts.setOnLoadCallback(drawappointmenttoday);
                    google.charts.setOnLoadCallback(drawappointmentweek);
                    google.charts.setOnLoadCallback(drawappointmentmonth);

                }
            });
    }


    const drawcallresulttoday = () => {
        const data = google.visualization.arrayToDataTable(state.fdtoday);
        const options = {
            title: 'Call Results by Hour (Today)',
            vAxis: {
                title: 'Record Count',
                minValue: 0
            },
            hAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            isStacked: true,
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.ColumnChart(document.getElementById('call_result_today'));

        chart.draw(data, options);
    }
    const drawcallresultweek = () => {
        const data = google.visualization.arrayToDataTable(state.fdweek);
        const options = {
            title: 'Call Results by Hour (This Week)',
            vAxis: {
                title: 'Record Count',
                minValue: 0
            },
            hAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            isStacked: true,
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.ColumnChart(document.getElementById('call_result_week'));

        chart.draw(data, options);
    }
    const drawcallresultlastweek = () => {
        const data = google.visualization.arrayToDataTable(state.fdlweek);
        const options = {
            title: 'Call Results by Hour (Last Week)',
            vAxis: {
                title: 'Record Count',
                minValue: 0
            },
            hAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            isStacked: true,
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.ColumnChart(document.getElementById('call_result_lastweek'));

        chart.draw(data, options);
    }
    const drawcallresultmonth = () => {
        const data = google.visualization.arrayToDataTable(state.fdmonth);
        const options = {
            title: 'Call Results by Hour (This Month)',
            vAxis: {
                title: 'Record Count',
                minValue: 0
            },
            hAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            isStacked: true,
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.ColumnChart(document.getElementById('call_result_month'));

        chart.draw(data, options);
    }
    const drawcallresultlastmonth = () => {
        const data = google.visualization.arrayToDataTable(state.fdlmonth);
        const options = {
            title: 'Call Results by Hour (Last Month)',
            vAxis: {
                title: 'Record Count',
                minValue: 0
            },
            hAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            isStacked: true,
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.ColumnChart(document.getElementById('call_result_lastmonth'));

        chart.draw(data, options);
    }
    const drawcallresulttodayper = () => {
        const data = google.visualization.arrayToDataTable(state.fdtoday);
        const options = {
            title: 'Call Results by Hour (Today %)',
            hAxis: {
                title: 'Record Count',
                minValue: 0
            },
            vAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            isStacked: 'percent',
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.BarChart(document.getElementById('call_result_today_percentage'));

        chart.draw(data, options);
    }
    const drawcallresultweekper = () => {
        const data = google.visualization.arrayToDataTable(state.fdweek);
        const options = {
            title: 'Call Results by Hour (This Week %)',
            hAxis: {
                title: 'Record Count',
                minValue: 0
            },
            vAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            isStacked: 'percent',
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.BarChart(document.getElementById('call_result_week_percentage'));

        chart.draw(data, options);
    }
    const drawcallresultlastweekper = () => {
        const data = google.visualization.arrayToDataTable(state.fdlweek);
        const options = {
            title: 'Call Results by Hour (Last Week %)',
            hAxis: {
                title: 'Record Count',
                minValue: 0
            },
            vAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            isStacked: 'percent',
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.BarChart(document.getElementById('call_result_lastweek_percentage'));

        chart.draw(data, options);
    }
    const drawcallresultmonthper = () => {
        const data = google.visualization.arrayToDataTable(state.fdmonth);
        const options = {
            title: 'Call Results by Hour (This Month %)',
            hAxis: {
                title: 'Record Count',
                minValue: 0
            },
            vAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            isStacked: 'percent',
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.BarChart(document.getElementById('call_result_month_percentage'));

        chart.draw(data, options);
    }
    const drawcallresultlastmonthper = () => {
        const data = google.visualization.arrayToDataTable(state.fdlmonth);
        const options = {
            title: 'Call Results by Hour (Last Month %)',
            hAxis: {
                title: 'Record Count',
                minValue: 0
            },
            vAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            isStacked: 'percent',
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.BarChart(document.getElementById('call_result_lastmonth_percentage'));

        chart.draw(data, options);
    }
    const drawappointmenttoday = () => {
        const data = google.visualization.arrayToDataTable(state.finalappointmentdata.aptToday);
        const options = {
            title: 'Appointments set by Hour (Today)',
            vAxis: {
                title: 'Record Count',
                minValue: 0
            },
            hAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.LineChart(document.getElementById('appointment_today'));

        chart.draw(data, options);
    }
    const drawappointmentweek = () => {
        const data = google.visualization.arrayToDataTable(state.finalappointmentdata.aptWeek);
        const options = {
            title: 'Appointments set by Hour (This Week)',
            vAxis: {
                title: 'Record Count',
                minValue: 0
            },
            hAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.LineChart(document.getElementById('appointment_week'));

        chart.draw(data, options);
    }
    const drawappointmentmonth = () => {
        const data = google.visualization.arrayToDataTable(state.finalappointmentdata.aptMonth);
        const options = {
            title: 'Appointments set by Hour (This Month)',
            vAxis: {
                title: 'Record Count',
                minValue: 0
            },
            hAxis: {
                title: 'Hour of Day'
            },
            legend: {position: "bottom", alignment: "start"},
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.LineChart(document.getElementById('appointment_month'));

        chart.draw(data, options);
    }

    const setTime = () => {
        const finalTime = moment().format('h:mm a');
        setStates({...state, finalTime: finalTime});
    }

    return (
        <Template activeLink="contact-data-report">
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div className="float-left mb10">
                                    <h3 className="mb0">Contact Data Report<HelpMark pageId='1'/></h3>
                                    <strong className="f12">{'As of today at ' + state.finalTime}</strong>
                                </div>
                                <div className="topFilters flex items-end flex-wrap">
                                    <Button className='ui primary button mb15' onClick={() => window.print()}>
                                        Print
                                    </Button>
                                    <Button className='ui primary button ml10 mb15' onClick={() => loadData()}>
                                        Refresh
                                    </Button>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            <div className="flex flex-wrap items-stretch dataReports">

                <div id={'appointment_today'}/>

                <div id={'appointment_week'}/>

                <div id={'appointment_month'}/>

                <div>
                    <h4>How many calls did we make per hour today?</h4>
                    <div id={'call_result_today'}/>
                </div>

                <div>
                    <h4>How many calls did we make per hour this week?</h4>
                    <div id={'call_result_week'}/>
                </div>
                <div>
                    <h4>How many calls did we make per hour this month?</h4>
                    <div id={'call_result_month'}/>
                </div>
                <div>
                    <h4>What was our contact % by hour today?</h4>
                    <div id={'call_result_today_percentage'}/>
                </div>
                <div>
                    <h4>What was our contact % by hour this week?</h4>
                    <div id={'call_result_week_percentage'}/>
                </div>
                <div>
                    <h4>What is our contact % by hour this month?</h4>
                    <div id={'call_result_month_percentage'}/>
                </div>
                <div>
                    <h4>How many calls did we make per hour last week?</h4>
                    <div id={'call_result_lastweek'}/>
                </div>
                <div>
                    <h4>How many calls did we make per hour last month?</h4>
                    <div id={'call_result_lastmonth'}/>
                </div>
                <div>
                    <h4>What was our contact % by hour last week?</h4>
                    <div id={'call_result_lastweek_percentage'}/>
                </div>
                <div>
                    <h4>What is our contact % by hour last month?</h4>
                    <div id={'call_result_lastmonth_percentage'}/>
                </div>
            </div>

        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        reports: state.reports.contactDataReport,
        loader: state.app.loader
    };
}

export default connect(mapStateToProps)(ContactDataReport);
