import React, {useEffect, useState} from "react"
import Template from "./Template"
import {useActions} from "../redux/actions";
import * as ReportActions from "../redux/actions/reports";
import {Button, Dropdown, Grid, Table} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import moment from "moment";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import HelpMark from "./help/HelpMark";
import {CSVLink} from "react-csv";

const datePickerFormat = "MM-dd-yyyy";

declare var google: any
const LeadDataReport: React.FC<any> = (props) => {
    const initialState: any = {
        practiceListOpt: null,
        practiceSelected: null,
        selectedDate: {
            startDate: '',
            endDate: ''
        },
        finalTime: '',
        weeklyleadEntered: '',
        monthlyleadEntered: '',
        dailyleadEntered: '',
        weeklyEntered: '',
        monthlyEntered: '',
        dailyEntered: '',
        lwleadEntered: '',
        ldleadEntered: '',
        lmleadEntered: '',
        leadsEntered: ''
    }

    google.charts.load('current', {'packages': ['corechart', 'bar']});
    const reportActions = useActions(ReportActions);
    const [state, setStates] = useState(initialState);
    useEffect(() => {
        loadData();
    }, []);


    const loadData = () => {
        const date = new Date();
        const d1 = new Date();
        d1.setHours(0, 0, 0, 0);
        const d2 = new Date();
        d2.setHours(23, 59, 59);
        const seven = new Date(new Date().setDate(new Date().getDate() + 7));
        seven.setHours(0, 0, 0, 0);
        const yesterday1 = new Date(new Date().setDate(new Date().getDate() - 1));
        yesterday1.setHours(0, 0, 0, 0);
        const yesterday2 = new Date(new Date().setDate(new Date().getDate() - 1));
        yesterday2.setHours(23, 59, 59);
        const thirty = new Date(new Date().setDate(new Date().getDate() - 30));
        thirty.setHours(0, 0, 0, 0);
        const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        thisMonth.setHours(0, 0, 0, 0);
        const first = date.getDate() - date.getDay();
        const last = first + 6;
        const weekStart = new Date(new Date().setDate(first));
        weekStart.setHours(0, 0, 0, 0);
        const weekEnd = new Date(new Date().setDate(last));
        weekEnd.setHours(23, 59, 59);
        const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1, 1));
        lastMonth.setHours(0, 0, 0, 0);
        const lastWeekStart = new Date(new Date().setDate(first - 7));
        lastWeekStart.setHours(0, 0, 0, 0);

        const dates = {
            d1: d1.toISOString(),
            d2: d2.toISOString(),
            yesterday1: yesterday1.toISOString(),
            yesterday2: yesterday2.toISOString(),
            thisMonth: thisMonth.toISOString(),
            lastMonth: lastMonth.toISOString(),
            weekStart: weekStart.toISOString(),
            weekEnd: weekEnd.toISOString(),
            lastWeekStart: lastWeekStart.toISOString()
        };
        reportActions.getLeadDataReport(dates)
    }

    const getLeadsByDates = () => {
        const dates = state.selectedDate;
        reportActions.getLeadDataReportByDates(dates)
            .then((res: { leadsEntered: any; }) => {
                const practiceListOpt = [{
                    key: 'all',
                    text: 'All',
                    value: 'all',
                }]
                res.leadsEntered && res.leadsEntered.forEach((item: { practice: string }) => {
                    const practiceOpt = {
                        key: item.practice,
                        text: item.practice,
                        value: item.practice,
                    }
                    if (item.practice && item.practice.toLowerCase() !== 'total') {
                        practiceListOpt.push(practiceOpt);
                    }
                })
                setStates({...state, practiceListOpt: practiceListOpt, leadsEntered: res.leadsEntered})
            })
    }

    const drawWeeklyleadEntered = () => {
        const data = google.visualization.arrayToDataTable(state.weeklyleadEntered);
        const options_stacked = {
            title: 'Entered This Week - Summary',
            vAxis: {
                title: "Record Counts"
            },
            hAxis: {
                title: "Location Preference",
                viewWindowMode: 'pretty',
                slantedText: true
            },
            legend: {
                position: 'none'
            },
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.ColumnChart(document.getElementById('weekly_lead_entered'));
        chart.draw(data, options_stacked);
    }

    const drawMonthlyleadEntered = () => {
        const data = google.visualization.arrayToDataTable(state.monthlyleadEntered);
        const options_stacked = {
            title: 'Entered MTD - Summary',
            vAxis: {
                title: "Record Counts"
            },
            hAxis: {
                title: "Location Preference",
                viewWindowMode: 'pretty',
                slantedText: true
            },
            legend: {
                position: 'none'
            },
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.ColumnChart(document.getElementById('monthly_lead_entered'));
        chart.draw(data, options_stacked);
    }

    const drawLmleadEntered = () => {
        const data = google.visualization.arrayToDataTable(state.lmleadEntered);
        const options_stacked = {
            title: 'Entered Last Month - Summary',
            vAxis: {
                title: "Record Counts"
            },
            hAxis: {
                title: "Location Preference",
                viewWindowMode: 'pretty',
                slantedText: true
            },
            legend: {
                position: 'none'
            },
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.ColumnChart(document.getElementById('lm_lead_entered'));
        chart.draw(data, options_stacked);
    }

    const drawDailyleadEntered = () => {
        const data = google.visualization.arrayToDataTable(state.dailyleadEntered);
        const options_stacked = {
            title: 'Entered Today - Summary',
            vAxis: {
                title: "Record Counts"
            },
            hAxis: {
                title: "Location Preference",
                viewWindowMode: 'pretty',
                slantedText: true
            },
            legend: {
                position: 'none'
            },
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.ColumnChart(document.getElementById('daily_lead_entered'));
        chart.draw(data, options_stacked);
    }

    const tableHeader = () => {
        return (
            <Table.Header>
                <Table.Row>
                  <Table.HeaderCell><div>Location Preference</div></Table.HeaderCell>
                  <Table.HeaderCell><div>Record Count</div></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }


    const getTableData = (items: any, cell1: string, cell2: string, filterByPractice: boolean) => {
        return (
            <Table className="ui table table-striped celled table-hover customTable unstackable">
                {tableHeader()}

                <Table.Body>
                    {items && items.map(function (leads: any) {
                        if (state.practiceSelected && state.practiceSelected !== 'all' && filterByPractice) {
                            if (leads[cell1].toLowerCase() === state.practiceSelected.toLowerCase()) {
                                return (
                                    <Table.Row>
                                        <Table.Cell>{leads[cell1]}</Table.Cell>
                                        <Table.Cell>{leads[cell2]}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        } else {
                            return (
                                <Table.Row>
                                    <Table.Cell>{leads[cell1]}</Table.Cell>
                                    <Table.Cell>{leads[cell2]}</Table.Cell>
                                </Table.Row>
                            )
                        }

                    })
                    }
                </Table.Body>
            </Table>
        )
    }

    const onChangeDate = (date: any, name: string | number) => {
        const {selectedDate} = state;
        selectedDate[name] = date;
        setStates({...state, selectedDate: selectedDate})
    };

    const handlePracticeFilter = (name: any, value: any) => {
        setStates({...state, practiceSelected: value})
    }

    const {reports} = props;
    if (reports) {
        state.finalTime = moment().format('h:mm a')
        state.weeklyleadEntered = reports.weeklyleadEntered;
        state.monthlyleadEntered = reports.monthlyleadEntered;
        state.dailyleadEntered = reports.dailyleadEntered;
        state.weeklyEntered = reports.weeklyEntered;
        state.monthlyEntered = reports.monthlyEntered;
        state.dailyEntered = reports.dailyEntered;
        state.lwleadEntered = reports.lwleadEntered;
        state.ldleadEntered = reports.ldleadEntered;
        state.lmleadEntered = reports.lmleadEntered;

        google.charts.setOnLoadCallback(drawWeeklyleadEntered);
        google.charts.setOnLoadCallback(drawMonthlyleadEntered);
        google.charts.setOnLoadCallback(drawDailyleadEntered);
        google.charts.setOnLoadCallback(drawLmleadEntered);
    }


    return (
        <Template activeLink="lead-data-report">
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div className="float-left mb10">
                                    <h3 className="mb0">Lead Data Report<HelpMark pageId='1'/></h3>
                                    <strong className="f12">{'As of today at ' + state.finalTime}</strong>
                                </div>
                                <div className="topFilters flex items-end flex-wrap">

                                    <Button className='ui primary button mb15 mr0' onClick={() => window.print()}>
                                        Print
                                    </Button>
                                    <Button className='ui primary button mb15 ml10 mr10' onClick={() => loadData()}>
                                        Refresh
                                    </Button>
                                    <div className="filter d-InlineBlock float-left labelTop">
                                        <label className="labelStyle displayBlock">Select Practice</label>
                                        <Dropdown
                                            placeholder='Select Practice'
                                            className='mr10 mb15'
                                            selection
                                            search={true}
                                            options={state.practiceListOpt}
                                            onChange={(e, {name, value}) => {
                                                handlePracticeFilter(name, value)
                                            }}
                                        />
                                    </div>
                                    <div className="filter d-InlineBlock float-left labelTop">
                                        <label className="labelStyle displayBlock">Start Date</label>
                                        <DatePicker className='ui input mb15 mr10' dateFormat={datePickerFormat}
                                                    selected={state.selectedDate.startDate}
                                                    onChange={(e) => onChangeDate(e, 'startDate')}/>
                                    </div>
                                    <div className="filter d-InlineBlock float-left labelTop">
                                        <label className="labelStyle displayBlock">End Date</label>
                                        <DatePicker className='ui input mb15 mr10' dateFormat={datePickerFormat}
                                                    selected={state.selectedDate.endDate}
                                                    onChange={(e) => onChangeDate(e, 'endDate')}/>
                                    </div>
                                    <Button className='ui primary button mb15 mr0' onClick={() => getLeadsByDates()}>
                                        Update
                                    </Button>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            <div className="flex flex-wrap items-stretch dataReports">
                <div>
                    <h4>Leads Entered Between Selected Dates</h4>
                    {getTableData(state.leadsEntered, 'practice', 'count', true)}
                </div>
                <div>
                    <h4>Entered Today - Detail</h4>
                    {getTableData(state.dailyEntered, 'practice', 'count', false)}
                </div>
                <div>
                    <h4>Entered This Week - Detail</h4>
                    {getTableData(state.weeklyEntered, 'practice', 'count', false)}
                </div>

                <div id={'daily_lead_entered'}/>

                <div id={'weekly_lead_entered'}/>

                <div id={'monthly_lead_entered'}/>

                <div>
                    <h4>Entered Yesterday - Detail</h4>
                    {getTableData(state.ldleadEntered, 'practice', 'count', false)}
                </div>
                <div>

                    <h4>Last Week - Detail</h4>
                    {getTableData(state.lwleadEntered, 'practice', 'count', false)}
                </div>
                <div>

                    <h4>Entered MTD - Detail</h4>
                    {getTableData(state.monthlyEntered, 'practice', 'count', false)}
                </div>

                <div id={'lm_lead_entered'}/>
            </div>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        reports: state.reports.leadDataReport
    };
}

export default connect(mapStateToProps)(LeadDataReport);
