import * as React from "react";
import {useEffect, useState} from "react";
import * as helpMarkActions from "../../redux/actions/helpMark";
import {HelpPages} from "../../constants";
import {Header, Icon, Modal} from "semantic-ui-react";

import {useActions} from "../../redux/actions";
import ViewFileModal from "./ViewFileModal";

interface Props {
  pageId: string
  hideHelpPageModal : any
}

function HelpPageModal(props: Props) {

  const helpMarkAction = useActions(helpMarkActions);

  const initialState : any = {
    openFileModal: false,
    files: null,
    data : null,
    fileType : ''
  }

  const [state, setStates] = useState(initialState);

  useEffect( () => {
    loadData();
  },[]);

  const loadData = () => {
    const obj = {
      pageId : props.pageId
    }

    helpMarkAction.getHelpById(obj)
    .then((res: object[]) => {
      if (res && res.length) {
        res.forEach((item: any) => {
          item.page = (
              (item.parentPageId && HelpPages[item.parentPageId] && HelpPages[item.pageId]) ?
                  `${HelpPages[item.parentPageId].name} > ${HelpPages[item.pageId].name}` :
                  HelpPages[item.pageId] ? HelpPages[item.pageId].name : ""
          );
        });
      }
      setStates({...state, data: res});
    });
  }

  const openFileModal = (type: string, files: any) => {
    setStates({...state, openFileModal: true, fileType: type, files: files});
  }

  const closeFilesModal = () => {
    setStates({...state, openFileModal: false, files: null, fileType: ''});
  }

  return (
      <div className="HelpPageModal">
        <Modal
            open={true}
            onClose={props.hideHelpPageModal}
            centered={false}
            size='large'
            closeIcon={true}
        >
          <Header content={`Help for ${(HelpPages[props.pageId]) ? (HelpPages[props.pageId].name && HelpPages[props.pageId].name.replace('MY.CDP - ','') ): ""}`}/>
          <Modal.Content>
            <div className="row">
              <div className='col-12'>
                <ul className="helpList mb-0">
                  { (state.data && state.data.length) ?
                      state.data.map((item: any, key: any) => {
                        return (

                            <li key={key}>
                              <strong>{item.heading}</strong>
                              <div>
                                {item.description && <p className="mb-2">{item.description}</p>}
                                { (item.fileLinks && item.fileLinks.toLowerCase().indexOf('.mp4') > 0) &&
                                    <span
                                        onClick={() => openFileModal('Video', item.fileLinks)}
                                        className="cursorPointer"
                                    >
															<Icon
                                  className="primaryColor mr-2"
                                  name="play circle"
                              />
															<span className="linkText">Watch Video</span>
													</span> }
                                { (item.fileLinks && item.fileLinks.toLowerCase().indexOf('.pdf') > 0) &&
                                    <span
                                        onClick={() => openFileModal('PDF', item.fileLinks)}
                                        className="cursorPointer"
                                    >
														<Icon
                                className="primaryColor mr-2"
                                name="file pdf"
                            />
														<span className="linkText">View PDF</span>
													</span> }
                              </div>
                            </li>

                        )
                      })
                      :
                      <div>No record found.</div>
                  }
                </ul>
              </div>
            </div>
          </Modal.Content>
          {state.openFileModal && <ViewFileModal closeModal={closeFilesModal} files={state.files} type={state.fileType}/>}

        </Modal>
      </div>
  );
}

export default HelpPageModal;
