import React, {useEffect, useState} from "react"
import Template from "./Template"
import {useActions} from "../redux/actions";
import * as ReportActions from "../redux/actions/reports";
import {Button, Dropdown, Grid, Table, Icon} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import moment from "moment";
import {CSVLink} from "react-csv";
import HelpMark from "./help/HelpMark";

interface PriorityData {
    finalTime: any;
    updatedData: boolean;
    reports: any;
    tempData: any;
    priority_status: any;
}

const PriorityStatusReport: React.FC<any> = (props: PriorityData) => {
    const initialState: PriorityData = {
        finalTime: '',
        updatedData: false,
        reports: [],
        tempData: [],
        priority_status: []
    }

    const reportActions = useActions(ReportActions);
    const [state, setStates] = useState(initialState);
    const [column, setColumn] = useState<any>(null);
    const [direction, setDirection] = useState<any>(null);
    const [regionPractices, setregionPractices] = useState<any>({});
    const [tempPracList, setTempList] = useState<any[]>([]);
    const [practizesList, setPracticeList] = useState<any[]>([]);
    const [regionsList, setRegionList] = useState<any[]>([]);
    const [activeRegion, setCurrentRegion] = useState<any>('')
    const [selectedPractice, setPractice] = useState<any>(0);
    const [reverseData, setDescending] = useState<any>([])

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (selectedPractice && selectedPractice !== 0) {
            let slected = new Array();
            state.tempData && slected.push(state.tempData[0])
            state.tempData && state.tempData.map((practiceItem: any) => {
                if (practiceItem.practiceId === selectedPractice) {
                    slected.push(practiceItem);
                    state.priority_status = slected;
                }
            });

            setStates({...state, updatedData: true, priority_status: slected});
            getTableData(state.priority_status)
        } else {
            setStates({...state, priority_status: state.tempData})
        }
    }, [selectedPractice])

    useEffect(() => {
        let regPrac = new Array();
        let regPriorityStatus = new Array();
        if (activeRegion && activeRegion != 'All') {
            state.priority_status = state.tempData;
            regPriorityStatus.push(state.tempData[0])
            Object.keys(regionPractices).length && activeRegion && regionPractices[activeRegion].map((practice: any, indx: number) => {
                regPrac.push({key: practice.practiceId, value: practice.practiceId, text: practice.practice});
                const currPrac = state.priority_status.filter((pracDtl: any) => pracDtl.practiceId === practice.practiceId);
                currPrac[0] && regPriorityStatus.push(currPrac[0]);
            });

            setStates({...state, priority_status: regPriorityStatus})
            setPracticeList(regPrac);
            setDirection(null);
            setColumn(null);
        } else {
            setPracticeList(tempPracList)
        }
    }, [activeRegion]);

    const loadData = () => {
        reportActions.getPriorityStatusReport();
        reportActions.GetPracticeList().then((data: any) => {
            let pList = [{key: 'All', value: 0, text: 'All'}];
            Object.keys(data) && data.practicesList.map((dtItem: any, index: number) => {
                pList.push({key: dtItem['practice'] + index, value: dtItem['id'], text: dtItem['practice']})
            });
            setPracticeList(pList);
            setTempList(pList)
        });
        reportActions.GetRegions().then((data: any) => {
            let regPracList: any = {}
            let rList = [{key: 'All', value: 'All', text: 'Select Region'}];
            Object.keys(data) && data.regionList.map((rgItem: any, index: number) => {
                rList.push({key: rgItem['name'] + index, value: rgItem['name'], text: rgItem['name']})
                regPracList[rgItem['name']] = rgItem.UdaPracticeInfo.map((item: any) => {
                    return {practice: item.practiceName, practiceId: item.practiceId}
                })
            });
            setRegionList(rList);
            setregionPractices(regPracList);
        })
    }

    const handleSort = (clickedColumn: any) => () => {
        const {priority_status} = state;
        if (column !== clickedColumn) {
            let srtData;
            srtData = priority_status.sort((a: any, b: any) => {
                if (a !== priority_status[0] || b !== priority_status[0]) {
                    return (parseInt(a[clickedColumn]) ? parseInt(a[clickedColumn]) : 0) - (parseInt(b[clickedColumn]) ? parseInt(b[clickedColumn]) : 0)
                }
            });

            setStates({
                ...state,
                priority_status: srtData
            });
            setColumn(clickedColumn);
            setDirection('ascending')
            return
        }
        const firstElement = priority_status.shift();
        let rvdata = priority_status.reverse();
        setStates({
            ...state,
            priority_status: rvdata.unshift(firstElement)
        })
        setDescending(rvdata)
        setDirection(direction === 'ascending' ? 'descending' : 'ascending');
    };

    const getTableData = (items: any) => {

        return (
            <Table sortable className="ui table table-striped celled table-hover customTable unstackable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className='sticky-col'>
                            <div>{items && items[0]['location']}</div>
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={column === 'P1' ? direction : null}
                                          onClick={handleSort(items && items[0]['P1'])}>
                            <div>{items && items[0]['P1']} {column !== items && items[0]['P1'] ?
                                <Icon name="sort"/> : ''}</div>
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={column === 'P2' ? direction : null}
                                          onClick={handleSort(items && items[0]['P2'])}>
                            <div>{items && items[0]['P2']} {column !== items && items[0]['P2'] ?
                                <Icon name="sort"/> : ''}</div>
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={column === 'P3' ? direction : null}
                                          onClick={handleSort(items && items[0]['P3'])}>
                            <div>{items && items[0]['P3']} {column !== items && items[0]['P3'] ?
                                <Icon name="sort"/> : ''}</div>
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={column === 'P4' ? direction : null}
                                          onClick={handleSort(items && items[0]['P4'])}>
                            <div>{items && items[0]['P4']} {column !== items && items[0]['P4'] ?
                                <Icon name="sort"/> : ''}</div>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <div>{items && items[0]['sum']}</div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {items && items.map(function (item: any, indx: number) {
                        if (indx !== 0) {
                            return (
                                <Table.Row key={indx}>
                                    <Table.Cell className='sticky-col'>{item['location']}</Table.Cell>
                                    <Table.Cell>{item['P1']}</Table.Cell>
                                    <Table.Cell>{item['P2']}</Table.Cell>
                                    <Table.Cell>{item['P3']}</Table.Cell>
                                    <Table.Cell>{item['P4']}</Table.Cell>
                                    <Table.Cell>{item['sum']}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    })}
                </Table.Body>
            </Table>
        )
    }

    const PriorityReportToCSV = () => {
        let toCSV = new Array();
        state.priority_status && state.priority_status.map((reportdata: object) => {
            toCSV.push(reportdata)
        })
        return toCSV

    }

    const {reports} = props;
    if (reports && reports.priority_status) {
        if (state.priority_status.length === 0 || direction !== null && selectedPractice === 0) {
            if (state.priority_status.length === 0 && activeRegion && activeRegion !== 'All') {
                state.priority_status = reports.priority_status;
                let reportRegion = new Array();
                reportRegion.push(reports.priority_status[0]);
                practizesList.forEach(prac => {
                    const reportItem = reports.priority_status.filter((itemp: any) => itemp.practiceId === prac.value)
                    reportItem[0] && reportRegion.push(reportItem[0]);
                });
                state.priority_status = reportRegion;
            } else if (!activeRegion || activeRegion == 'All') {
                state.priority_status = reports.priority_status;
            } else if (state.priority_status.length == undefined && activeRegion && (direction == 'descending' || direction == 'ascending')) {
                state.priority_status = reverseData;
            }
        }
        state.tempData = reports.priority_status;
        state.finalTime = moment().format('h:mm a')
    }


    return (
        <Template activeLink="priority-status-report">
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div className="float-left mb10">
                                    <h3 className="mb0">Priority Status Report<HelpMark pageId='1'/></h3>
                                    <strong className="f12">{'As of today at ' + state.finalTime}</strong>
                                </div>
                                <div className="topFilters flex items-end flex-wrap">
                                    <CSVLink
                                        data={state.priority_status.length > 0 ? PriorityReportToCSV() : []}
                                        filename="PriorityStatus.csv"
                                    >
                                        <Button className='ui primary button mb15 mr0'>
                                            Export CSV
                                        </Button>
                                    </CSVLink>
                                    <Button className='ui primary button ml10 mr10 mb15' onClick={() => loadData()}>
                                        Refresh
                                    </Button>
                                    <Dropdown className='mr10 mb15' placeholder='Select Practice' search selection
                                              options={practizesList} onChange={(e, {value}) => setPractice(value)}/>
                                    <Dropdown className='mb15' placeholder='Select Region' search selection
                                              options={regionsList} onChange={(e, {value}) => setCurrentRegion(value)}/>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    <div className='FreezeTable'>
                        {state.priority_status.length > 0 && getTableData(state.priority_status)}
                    </div>
                </div>
            </div>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        reports: state.reports.priorityStatusReport
    };
}

export default connect(mapStateToProps)(PriorityStatusReport);
