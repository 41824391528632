import { ActionType } from "../../model/actionType";
import {errorHandler} from "./auth";
export const saveApplicationUsage = (name: Object) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        dispatch({ type: ActionType.SAVE_APP_USAGE, payload: {} });
        const data = {
            ...name
        };
        const resp = await api.post("saveApplicationUsage", data);
        dispatch({ type: ActionType.SAVE_APP_USAGE_SUCCESS, payload: resp });
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};