import {combineReducers} from "redux"
import * as authReducer from "./user";
import * as appReducer from "./app"
import * as alertReducer from "./alert"
import * as membersReducer from "./members"
import * as callRecordingsReducer from "./callRecordings"
import * as reports from "./reports"
export default ()=>
   combineReducers({
       ...authReducer,
       ...appReducer,
       ...alertReducer,
       ...membersReducer,
       ...callRecordingsReducer,
       ...reports
   })