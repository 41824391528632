import { ActionType } from "../../model/actionType";
import {errorHandler} from "./auth";
import moment from "moment";
export const getUniversalUsageReport = (startDate1:any,endDate1:any, userSearch: string) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        let stringifyParams;
        let startDate:any = moment.parseZone(startDate1).startOf('day').utc();
        let endDate:any = moment(endDate1).endOf('day').utc();
        const params = { startDate,endDate,userSearch}
        stringifyParams = '/'+JSON.stringify(params)
        const resp =  await api.get('report/usage'+stringifyParams);
        dispatch({ type: ActionType.FETCH_UNIVERSAL_USAGE_REPORT_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
