import React, {useEffect, useMemo, useRef, useState} from "react";
import Template from "./Template";
import {useHistory, useLocation} from "react-router-dom";
import {
    brands,
    getCookie,
    McoListOptions,
    memberResult,
    membersCrons,
    membersResultFilters,
    membersStatusFilters,
    membersTableHeaders
} from "../constants";
import {Button, Dropdown, Grid, Input, Pagination, PaginationProps,} from "semantic-ui-react";
import {useActions} from "../redux/actions";
import * as MembersActions from "../redux/actions/members";
import {App, MemberPayload, PracticeSchema, RootState} from "../model";
import {connect} from "react-redux";
import {DentistType, MembersItem} from "../model/members";
import NotesModal from "./NotesModal";
import {useFuse} from "../hooks/useSearch";
import {Account} from "../model/user";
import {Loading} from "./Loading";
import moment from 'moment';
import * as UserSetupActions from "../redux/actions/userSettings";
import MembersCronsModal from "./MembersCronsModal";
import {validArr} from "../utils/arrayUtils";
import {SetupFields} from "../enums/userSetupField";
import {SetupAppName} from "../enums/userSetupAppNames";
import HelpMark from "./help/HelpMark";
import ResultsModal from "./ResultsModal";
import { myCDPOpenIssuesApplicationList } from '../constants';

interface MembersProps {
    membersList: Array<MembersItem>;
    currentCount: number;
    pageNumber: number;
    dentistList: Array<DentistType>;
    practicesList: Array<PracticeSchema>;
    regionsList:any,
    user: Account;
    app: App;
    cronData: []
}

const Members: React.FC<MembersProps> = (props: MembersProps) => {
    const cookieFilters = getCookie('memberFilters');
    const filters = cookieFilters ? JSON.parse(cookieFilters) : {};
    const defaultFilters = props.app.defaultSetup;
    let defaultLocationId = !!props.user.defaultLocationId ? props.user.defaultLocationId.split(';') : [];
    const userDefaultLocationId = defaultLocationId.length > 25 ? 'All' : validArr(defaultLocationId) ? defaultLocationId.map(id => {
        const Practice = props.practicesList.find((practice: PracticeSchema) => practice.id.toString() === id);
        return Practice ? Practice.practiceabbr : '';
    }) : '';
    let location = useLocation();
    const membersActions = useActions(MembersActions);
    const userSetupActions = useActions(UserSetupActions);
    const hist = useHistory();

    const params = new URLSearchParams(window ? window.location.search : {});
    const practice = params.get("practice");
    const status = params.get("status");
    const brand = params.get("brand");
    const currentCompany = useRef(filters.currentCompany ? filters.currentCompany : "All");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentDentistFilter, setDentistFilter] = useState("");
    const [currentResultFilter, setResultFilter] = useState("");
    const [currentMcoFilter, setMcoFilter] = useState("");
    const [currentBrandFilter, setBrandFilter] = useState("");
    const [canAddNotes, setCanAddNotes] = useState(false);
    const [cronsModal, setCronsModal] = useState(false);
    const [cronData, setCronData] = useState(props.cronData);
    const [currentNotesItem, setCurrentNotesItem] = useState<MembersItem>();
    const [currentPractice, setCurrentPractice] = useState(practice || userDefaultLocationId);
    const [currentRegion, setCurrentRegion] = useState("All");
    const [currentStatusFilter, setStatusFilter] = useState(status || (defaultFilters && defaultFilters.memberStatus));
    const [currentOrderBy, setOrderBy] = useState(filters.currentOrderBy ? filters.currentOrderBy : "");
    let [currentSearch, onSearchEnter] = useState("");
    const [count, setCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [currColumn, setSameColumn] = useState(false);
    const [reload, setReload] = useState(0);
    const [initStart, setInitStart] = useState(true);
    const [showResultHistory, setShowResultHistory] = useState(false);
    const [isThereOpenIssues, setIsThereOpenIssues] = useState(false);

    let timeout :any = 0;
    useEffect(() => {
        setLoader(true);

        setTimeout(function () {
            checkOpenIssues();
            membersActions.GetRegions();
            membersActions.getDentistList();
            parseLocation();
            membersActions.GetPractices(currentCompany.current.includes('dashboard') ? "All" : currentCompany.current);
        }, 1000);
        membersActions.getCronList()
            .then((res: any) => {
                setCronData(res);
            });
        setLoader(false)
    }, [currentBrandFilter]);

    useEffect(() => {
        loadUserSettings();
    }, []);

    useEffect(() => {
        const url = `?practice=${currentPractice ?? (practice || '')}&status=${currentStatusFilter ?? (status || '')}&brand=${currentBrandFilter ?? (brand || '')}`;
        hist.push(url);
        setTimeout(function async() {
            parseLocation();
            !initStart && loadMemberData();
        }, 1000);
    }, [
        currentDentistFilter,
        currentPage,
        currentResultFilter,
        currentMcoFilter,
        currentStatusFilter,
        currentBrandFilter,
        currentSearch,
        currentOrderBy,
        currentPractice,
        currColumn,
        reload,
        initStart
    ]);

    useEffect(() => {
        if(initStart) {

            onSearchEnter("");
            setBrandFilter("");
            setDentistFilter("");
            setMcoFilter("");
            setCurrentPractice("");
            setResultFilter("");
            setStatusFilter("");

            updateCurrentPractice("All");
        }
    });

    const checkOpenIssues = async() => {
        const result: any = await membersActions.getOpenIssues(myCDPOpenIssuesApplicationList);
        setIsThereOpenIssues(result.length > 0 ? true : false);
    }

    const loadUserSettings = async () => {
        const getStatusParams = {
            field: SetupFields.Practice,
            appId: SetupAppName.CALL_CENTER
        }
        const response = await userSetupActions.getSetup(getStatusParams)
        if (response) {
            const {practice, status} = response;
            if (status && practice) {
                status.value && setStatusFilter(status.value);
                practice.value && setCurrentPractice(practice.value);
                loadMemberData(practice.value, status.value)
            } else if (practice) {
                practice.value && setCurrentPractice(practice.value);
                loadMemberData(practice.value);
            } else if (status) {
                status.value && setStatusFilter(status.value);
                loadMemberData(currentPractice, status.value);
            }
        }
    }
    

    const loadMemberData = (memPratice?: any, memStatus?: string) => {
        setLoader(true);
        const payload: MemberPayload = {
            currentPage: currentPage,
            currentCompany: (currentBrandFilter ? currentBrandFilter : (currentCompany.current.includes('dashboard') ? "All" : currentCompany.current) || brand),
            currentDentistFilter: currentDentistFilter,
            currentResultFilter: currentResultFilter,
            currentMcoFilter: currentMcoFilter,
            currentStatusFilter: memStatus || currentStatusFilter || status,
            currentOrderBy: currentOrderBy,
            currentPractice: memPratice || currentPractice || practice,
            currentRegion:currentRegion,
            currentSearch: currentSearch,
            currColumn: currColumn
        };
        membersActions.getMembers(payload).then((res: any) => {
            setCount(res.total_count);
            setLoader(false);
        });
    }

    const {hits, query} = useFuse(props.membersList, {
        keys: ["First_Name", "Last_Name", "Middle_Name", "Subscriber_ID", "PatNum", "Phone", "Date_of_Birth"],
        matchAllOnEmptyQuery: true,
        threshold: 0.1,
        ignoreLocation: true,
        findAllMatches: true,
    });

    const paginationButtonBar = () => {
        return (
            <Pagination
                defaultActivePage={1}
                totalPages={30}
                onPageChange={(_, data: Readonly<PaginationProps>) => {
                    const page = data.activePage as number;
                    setCurrentPage(page);
                }}
            />
        );
    };

    const doSearch = (event: any) => {
        const value = event.target.value;
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            onSearchEnter(value);
            setInitStart(false);
        }, 750);
    }

    const parseLocation = () => {
        let currentLocationPath = location.pathname.replace("/members/", "");
        currentLocationPath = currentLocationPath.replace("/dashboard/", "all");
        if (currentLocationPath.indexOf("-") !== -1) {
            return currentCompany.current = currentLocationPath
                .split("-")
                .map((item: string) => item.charAt(0).toUpperCase() + item.slice(1))
                .join(" ");
        } else {
            return currentCompany.current = currentLocationPath.charAt(0).toUpperCase() +
                currentLocationPath.slice(1);
        }
    };

    const openNotesModal = (currentItem: MembersItem) => {
        setCurrentNotesItem(currentItem);
        setCanAddNotes(true);
    };

    const openCronsModal = () => {
        setCronsModal(true);
    };

    const viewResultHistory =(item: any) => {
        setShowResultHistory(item)
    }

    const closeNotesModal = () => {
        setCanAddNotes(false);
    };
    const hideNotesModal = () => {
        setCanAddNotes(false);
    };
    const closeCronsModal = () => {
        setCronsModal(false);
    };
    const hideCronsModal = () => {
        setCronsModal(false);
    };

    const submitNotes = (notes: string) => {
        setCanAddNotes(false);
        const payload = {
            subscriberid    :   currentNotesItem?.Subscriber_ID,
            patNumber       :   currentNotesItem?.PatNum,
            practice       :   currentNotesItem?.Practice,
            notes: notes,
        };
        const AsyncSubmit = async () => {
            membersActions.SubmitNotes(payload)
                .then(() => {
                    loadMemberData();
                    setReload(reload + 1);
                });

        };
        AsyncSubmit();
    };
    const submitResult = (result: string, item: MembersItem) => {
        const payload = {
            sid: item?.Subscriber_ID,
            Result: result,
            patnum       :   item.PatNum,
            practice       :   item.Practice,
            addLocation : 'callCenter'

        };
        const AsyncSubmit = async () => {
            membersActions.SaveResult(payload).then(() => {
                loadMemberData();
                setReload(reload + 1);
            });
        };
        AsyncSubmit();
    };

    const getAge = (dob: string) => {
        const diff = moment.duration(moment().diff(dob));
        const years = diff.years();
        let ageString = years > 0? `${years} years` : ``;
        ageString += ` ${diff.months()} months ${diff.days()} days`;
        return ageString;
    }
    const makeNotesRow = (item: MembersItem) => {
        const notes: string = item.notes;
        return (
            <span>
                {notes ? notes.slice(0, 27) + "...." : ''}
                 <Button
                     primary
                     className="mb5"
                     onClick={() => {
                         openNotesModal(item);
                     }}
                 >
                    Add Notes
                </Button>

      </span>
        );
    };
    const makeNotesTimeRow = (item: MembersItem) => {
        const notestimestamp: any = item.notestimestamp;
        return (
            <span>
                {notestimestamp ? moment(new Date(notestimestamp)).format('MM/DD/YYYY h:mmA').toUpperCase() : ''}
            </span>
        );
    };
    const makeResultRow = (item: MembersItem) => {
        const resultOptions = [
            {value: "Select Value", key: "Select Result", text: "Select Result"},
            ...membersResultFilters,
        ];

        return (
            <span>
                 <Button
                     primary
                     onClick={() => {
                         viewResultHistory(item)
                     }}
                 > View History  </Button>
        <Dropdown
            placeholder="Select Result"
            fluid
            selection
            defaultValue={item.Result}
            options={resultOptions}
            onChange={(_event, data) => {
                submitResult(data.value as string, item);
            }}
        />
      </span>
        );
    };

    const getRowStyle = (result: string) => {
        switch (result) {
            case "Appointment Made":
                return {
                    backgroundColor: "green",
                };
            case "Contact Made Call Back":
                return {
                    backgroundColor: "yellow",
                };
            case "Left Message":
                return {
                    backgroundColor: "lightblue",
                };
            case "Phone number issues":
                return {
                    backgroundColor: "lightgrey",
                };
            case "DUPLICATE RECORD":
                return {
                    backgroundColor: "orange",
                };
            case "Do Not Contact":
                return {
                    backgroundColor: "lightgrey",
                };
            case "No Answer":
                return {
                    backgroundColor: "lightblue",
                };
            case "Other":
                return {
                    backgroundColor: "lightgrey",
                };
            case "Need Review":
                return {
                    backroundColor: "white",
                };
            default:
                return {
                    backgorundColor: "lightgrey",
                };
        }
    };
    const cleanSearchResult = (_data: any) => {
        return hits.map((hit: any) => {
            return {...hit.item as MembersItem}
        })
    }

    const getPracticeName = (abbr: string) => {
        const practice = props.practicesList.find((practice: PracticeSchema) => practice.practiceabbr === abbr);
        return practice?.practice
    }

    const makeBodyMarkup = (data: Array<MembersItem>) => {
        return data.map((item: MembersItem) => (
            <tr style={getRowStyle(item.Result)} key={item.id + `${Math.random()}`}>
                <td>{item.Subscriber_ID}</td>
                <td>{item.PatNum ? item.PatNum : ""}</td>
                <td>{item.MCO}</td>
                <td>{item.First_Name}</td>
                <td>{item.Last_Name}</td>
                <td>{getPracticeName(item.Practice)}</td>
                <td style={{width: '150px'}}>{item.Address1}</td>
                <td>{item.City}</td>
                <td>{item.State}</td>
                <td>{item.Phone}</td>
                <td>{item.Date_of_Birth ? moment(item.Date_of_Birth).format('MM/DD/YYYY').toUpperCase() : ''}</td>
                <td>{getAge(item.Date_of_Birth)}</td>
                <td style={{width: '62px'}}>{item.Days_Extracted}</td>
                <td>{makeNotesRow(item)}</td>
                <td>{makeNotesTimeRow(item)}</td>
                <td>{item.Status}</td>
                <td>{makeResultRow(item)}</td>
                <td>{item.LastVisit ? moment(item.LastVisit).format('MM/DD/YYYY').toUpperCase() : "No LastVisit"}</td>
                <td>{item.NextApt ? moment(item.NextApt).format('MM/DD/YYYY').toUpperCase() : "No NextApt"}</td>
                <td>{item.entryTime ? moment(item.entryTime).format('MM/DD/YYYY hh:mm:ss') : ''}</td>
            </tr>
        ));
    };
    const generateDentistFilterOptions = () => {
        if (typeof props.dentistList === "undefined") {
            return [];
        }
        return props.dentistList.map((dentist) => {
            return {
                key: dentist.Dr_First_Name,
                value: dentist.Dr_First_Name,
                text: dentist.dentist,
            };
        });
    };

    const handleHeaderClick = (databaseValue: string) => {
        if (currentOrderBy === databaseValue) {
            setSameColumn(true)
        } else {
            setSameColumn(false)
            setOrderBy(databaseValue);
        }

    };

    const updateCurrentPractice = (practiceC: any) => {
        setCurrentPractice(practiceC);
        setInitStart(false);
    };
    const updateCurrentRegion = (regionC: any) => {
        setCurrentRegion(regionC);
        setCurrentPractice("All");
        setInitStart(false);
    };

    const tableData = () => {
        return(<table className="ui table table-striped celled table-hover customTable unstackable">
            <thead>
            <tr>
                {membersTableHeaders.map((item, index) => (
                    <th
                        onClick={() => {
                            handleHeaderClick(item.databaseValue);
                            setInitStart(false);
                        }}
                        style={{color: "#d16f26"}}
                        key={index}
                    >
                        <div>
                            {item.title}
                        </div>
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>{
                query ? makeBodyMarkup(cleanSearchResult(hits)) : makeBodyMarkup(props.membersList)

            }</tbody>
        </table>)
    }

    const generateTableData = useMemo(() => tableData, [props.membersList]);

    const lightPracticeClass: any = currentPractice === "All" || currentPractice === "" || currentPractice === [] || currentPractice === undefined ? "filter d-InlineBlock float-left memberResultA labelTop" : " filter d-InlineBlock float-left memberResultB labelTop";
    const lightPracticeStatusClass: any = currentStatusFilter === "All" || currentStatusFilter === "" || currentStatusFilter === undefined ? "filter d-InlineBlock float-left memberResultA labelTop" : " filter d-InlineBlock float-left memberResultB labelTop";
    const lightPracticeResultClass: any = currentResultFilter === "All" || currentResultFilter === "" || currentResultFilter === undefined ? "filter d-InlineBlock float-left memberResultA labelTop" : " filter d-InlineBlock float-left memberResultB labelTop";
    const lightPracticeMcoClass: any = currentMcoFilter === "All" || currentMcoFilter === "" || currentMcoFilter === undefined ? "filter d-InlineBlock float-left memberResultA labelTop" : " filter d-InlineBlock float-left memberResultB labelTop";
    const lightPracticeCurrentDentistFilterClass: any = currentDentistFilter === "" || currentDentistFilter === "All" ? "filter d-InlineBlock float-left memberResultA labelTop" : " filter d-InlineBlock float-left memberResultB labelTop";

    const lightPracticeBrandClass: any = currentBrandFilter === "All" || currentBrandFilter === "" || currentBrandFilter === undefined ? "filter d-InlineBlock float-left memberResultA labelTop" : " filter d-InlineBlock float-left memberResultB labelTop";
    return (
        <Template activeLink="members">
            {loader && <Loading/>}
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <h3 className="float-left">Members {(currentCompany.current.includes('dashboard') ? "All" : currentCompany.current)} ({count}) 
                                    <HelpMark 
                                        pageId='1' 
                                        isThereOpenIssues={{status: isThereOpenIssues, openIssues:myCDPOpenIssuesApplicationList}} 
                                    />
                                </h3>

                                <div className="topFilters flex items-end flex-wrap">

                                    <div className={lightPracticeClass}>
                                        <label className="labelStyle displayBlock">Filter By Practice</label>
                                        {!loader && <Dropdown
                                            className="mr10 mb15"
                                            placeholder="Select Practice"
                                            selection={true}
                                            search
                                            multiple={false}
                                            value={currentPractice || []}
                                            options={props.practicesList
                                                ? [{
                                                    key: 'All',
                                                    value: 'All',
                                                    text: 'All'
                                                }, ...props.practicesList.sort((a, b) => (a.practice > b.practice) ? 1 : -1).map(practice => {
                                                    return {
                                                        key: practice.id,
                                                        value: practice.practiceabbr,
                                                        text: practice.practice,
                                                    };
                                                })]
                                                : []}
                                            onChange={(_event, data) => {
                                                updateCurrentPractice(data.value);
                                            }}
                                        />}
                                    </div>
                                    <div className={lightPracticeStatusClass}>
                                        <label className="labelStyle displayBlock">Filter By Status</label>
                                        {!loader && <Dropdown
                                            className="mr10 mb15"
                                            placeholder="Select Status"
                                            selection
                                            defaultValue={currentStatusFilter}
                                            options={[
                                                {key: "All", value: "all", text: "All"},
                                                ...membersStatusFilters,
                                            ]}
                                            onChange={(_event, data) => {
                                                setStatusFilter(data.value as string);
                                                setInitStart(false);
                                            }}
                                        />}
                                    </div>
                                    <div className={lightPracticeResultClass}>
                                        <label className="labelStyle displayBlock">Filter By Result</label>
                                        <Dropdown
                                            className="mr10 mb15"
                                            placeholder="Select Result"
                                            selection
                                            defaultValue={currentResultFilter}
                                            options={[
                                                {key: "All", value: "All", text: "All"},
                                                ...membersResultFilters.sort((a, b) => (a.text > b.text) ? 1 : -1)
                                                ,
                                            ]}
                                            onChange={(_event, data) => {
                                                setResultFilter(data.value as string);
                                                setInitStart(false);
                                            }}
                                        />
                                    </div>
                                    <div className={lightPracticeMcoClass}>
                                        <label className="labelStyle displayBlock">Filter By MCO</label>
                                        <Dropdown
                                            className="mr10 mb15"
                                            placeholder="Select MCO"
                                            selection
                                            defaultValue={currentMcoFilter}
                                            options={[
                                                {key: "All", value: "All", text: "All"},
                                                ...McoListOptions,
                                            ]}
                                            onChange={(_event, data) => {
                                                setMcoFilter(data.value as string);
                                                setInitStart(false);
                                            }}
                                        />
                                    </div>
                                    <div className={lightPracticeCurrentDentistFilterClass}>
                                        <label className="labelStyle displayBlock">Filter By Dentist</label>
                                        <Dropdown
                                            className="mr10 mb15"
                                            placeholder="Select Dentist"
                                            selection
                                            defaultValue={currentDentistFilter}
                                            options={[
                                                {key: "All", value: "", text: "All"},
                                                ...generateDentistFilterOptions(),
                                            ]}
                                            onChange={(_event, data) => {
                                                setDentistFilter(data.value as string);
                                                setInitStart(false);
                                            }}
                                        />
                                    </div>
                                    <div className={lightPracticeBrandClass}>
                                        <label className="labelStyle displayBlock">Filter By Brand</label>
                                        <Dropdown
                                            className="mr10 mb15"
                                            placeholder="Select Brand"
                                            selection
                                            defaultValue={currentBrandFilter}
                                            options={[
                                                ...brands
                                            ]}
                                            onChange={(_event, data) => {
                                                setBrandFilter(data.value as string);
                                                setInitStart(false);
                                                updateCurrentPractice('All');
                                            }}
                                        />
                                    </div>
                                    <div className="filter d-InlineBlock float-left labelTop">
                                        <label className="labelStyle displayBlock">Search</label>
                                        <Input
                                            className="mr10 mb15"
                                            focus
                                            placeholder="Search"
                                            defaultValue={currentSearch}
                                            onChange={(event: any) => doSearch(event)}
                                        />
                                    </div>
                                    <Button
                                        className="ml10 mb15"
                                        primary
                                        onClick={() => {
                                            openCronsModal();
                                        }}
                                    >Crons
                                    </Button>
                                    {cronsModal && (
                                        <MembersCronsModal
                                            hideCronsModal={hideCronsModal}
                                            closeCronsModal={closeCronsModal}
                                            cronData={cronData.filter((item) => (membersCrons.indexOf(item['title']) >= 0))}
                                        />
                                    )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            {canAddNotes && (
                                <NotesModal
                                    hideNotesModal={hideNotesModal}
                                    closeNotesModal={closeNotesModal}
                                    submitNotes={submitNotes}
                                    item={currentNotesItem}
                                />
                            )}

            {showResultHistory && <ResultsModal
                hideModal={setShowResultHistory}
                item={showResultHistory}
            />}
            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable mb15">
                        {generateTableData()}
                    </div>
                    {paginationButtonBar()}
                </div>

            </div>
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        membersList: state.members.members,
        currentCount: state.members.currentCount,
        pageNum: state.members.currentPage,
        dentistList: state.members.dentistList,
        practicesList: state.members.practicesList,
        regionsList: state.members.regionsList,
        user: state.auth.user,
        app: state.app,
    };
}

export default connect(mapStateToProps)(Members);
