import { Action, ActionType } from "../../model/actionType";
import { Members } from "../../model/members";
import createReducer from "./createReducer";
const initialState = {
  members: [],
  currentPage: 1,
  currentCount: 0,
  dentistList: [],
  practicesList:[],
  regionsList:[],
  membersStatusReport : []
};

export const members = createReducer(initialState, {
  [ActionType.LOAD_MEMBERS](state: Members, action: Action<Members>) {
    return Object.assign({ ...state }, action.payload);
  },
  [ActionType.LOAD_DENTIST_LIST](state: Members, action: Action<Members>) {
    return Object.assign({ ...state }, action.payload);
  },
  [ActionType.LOAD_OPEN_ISSUES_LIST](state: Members, action: Action<Members>) {
    return Object.assign({ ...state }, action.payload);
  },
  [ActionType.LOAD_PRACTICES](state:Members,action:Action<Members>){
    return Object.assign({...state},action.payload)
  },
  [ActionType.LOAD_REGIONS](state:Members,action:Action<Members>){
    return Object.assign({...state},action.payload)
  },
  [ActionType.LOAD_MEMBERS_STATUS_REPORT](state: any, action: Action<any>) {
    return Object.assign({ ...state }, {membersStatusReport : action.payload});
  }
});
