import React from "react";
import {Modal, Header, Table} from "semantic-ui-react";

const MembersCronsModal:React.FC<any>=(props)=>{

  
    return (
         <Modal
             open={true}
             onClose={props.hideCronsModal}
             centered={false}
             closeIcon
             size='large'
         >
            <Header content='Members Crons'/>
            <Modal.Content scrolling>

            <Table celled structured className="table tableCustomStyle mb0">
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell><div className="tbl-header">Is Cron Running</div></Table.HeaderCell>
                <Table.HeaderCell><div className="tbl-header">Title</div></Table.HeaderCell>
                <Table.HeaderCell><div className="tbl-header">Description</div></Table.HeaderCell>
                <Table.HeaderCell><div className="tbl-header">Cron Time</div></Table.HeaderCell>
                <Table.HeaderCell><div className="tbl-header">Start Time</div></Table.HeaderCell>
                <Table.HeaderCell><div className="tbl-header">End Time</div></Table.HeaderCell>
                <Table.HeaderCell><div className="tbl-header">Cron Status</div></Table.HeaderCell>
                <Table.HeaderCell><div className="tbl-header">Cron Message</div></Table.HeaderCell>
            </Table.Row>
            </Table.Header>

            <Table.Body>
            {props.cronData &&
                props.cronData.map((item: any, index: any) => {
                  return(<>
                    <Table.Row key={index}>
                      <Table.Cell rowSpan={item.logs.length + 1}>{item.reRun=="0"?"Already ran":"Running"}</Table.Cell>
                      <Table.Cell rowSpan={item.logs.length + 1}>{item.title}</Table.Cell>
                      <Table.Cell rowSpan={item.logs.length + 1}>{item.description}</Table.Cell>
                      <Table.Cell rowSpan={item.logs.length + 1}>{item.cronTime}</Table.Cell>
                    </Table.Row>
                          {item.logs && item.logs.map((row: any) => <Table.Row>
                              <Table.Cell>{row.startTime}</Table.Cell>
                              <Table.Cell>{row.endTime}</Table.Cell>
                              <Table.Cell>{row.status}</Table.Cell>
                              <Table.Cell className={'whiteSpace'}>{row.message}</Table.Cell>
                          </Table.Row>)}

                      </>
                  )
                })}
            </Table.Body>
            </Table> 
            </Modal.Content>
            <Modal.Actions>
            </Modal.Actions>
        </Modal>
    )
}

export default MembersCronsModal