import {ActionType} from "../../model/actionType"
import {errorHandler} from "./auth"

export const getPractices = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const practiceList = await api.get(`practice/list`)
        dispatch({type: ActionType.LOAD_PRACTICELIST, payload: practiceList})
        return practiceList;
    } catch (err) {
        return dispatch(errorHandler(err))
    }
}





