/* eslint-disable no-useless-escape */
import React,{useEffect, useState} from "react"
import { Sidebar, Segment, Dimmer, Loader } from "semantic-ui-react"
import SideBarMain from "./SideBarMain"
import HeaderMain from "./Header"
import {Alert, App, RootState} from '../model';
import {Account} from "../model/user"
import {useActions} from '../redux/actions';
import * as authActions from '../redux/actions/auth';
import * as appActions from '../redux/actions/app';
import * as UserSetupActions from '../redux/actions/userSettings'
import {connect} from "react-redux"
import { SetupFields } from "../enums/userSetupField";
import { SetupAppName } from "../enums/userSetupAppNames";
import { deleteCookie } from "../constants";
import {Loading} from './Loading';
interface Props {
  activeLink: string,
  children: any,
  app: App,
  user: Account
  alert:Alert,
  mycdp_uri:string
}
const Template:React.FC<Props>=(props)=>{
  const authAction = useActions(authActions);
  const appAction = useActions(appActions);
  const userSetupActions = useActions(UserSetupActions);
  const [defaultSetup,setDefaultOptions]=useState({
    memberPractice:"",
    memberStatus:""
  });
  const listenerFromParent = (): void => {
    appAction.SidebarOpen(!(props.app.leftSidebarOpen))
  };
  
  useEffect(() => {
    props.user && props.user.id &&  authAction.getDentalPartners(props.user.id);
  }, []);

  const logout = async() => {
    await deleteCookie();
    await authAction.LogOut();
  };

  const loadUserSettings=async()=>{
    const getStatusParams={
      field: SetupFields.Practice, 
      appId: SetupAppName.CALL_CENTER
    }
    const response=await userSetupActions.getSetup(getStatusParams)
    if(response){
      const {practice,status}=response;
      let tempDefault:any={};
      if(status && status.value)   
          tempDefault={memberStatus:status.value};
      if(practice&&practice.value)
          tempDefault={...tempDefault,memberPractice:practice.value}
      setDefaultOptions({...tempDefault});
    }
  }

  const handleClick = async (e:any) => {
    e.preventDefault();
    const mycdp_uri :any = props.mycdp_uri;
    if(mycdp_uri){
       window.location.href = mycdp_uri;
    }
  };
  const {leftSidebarOpen, loader} = props.app;
    return (
        <div className="cdp-udr">
          <Sidebar.Pushable className='mt-0' as={Segment}>
            <SideBarMain handleClick={handleClick} visible={leftSidebarOpen} user={{}}/>
          <Sidebar.Pusher>
          <Segment basic>
            <HeaderMain defaultSetup={defaultSetup} handleClick={handleClick} listenerFromParent={listenerFromParent} logout={logout} user={props.user} mycdp_uri={props.mycdp_uri}/>
            <div className="container-fluid containerMain">
              { loader && <Dimmer active inverted>
                <Loading/>
              </Dimmer>
              }
              { props.children }
            </div>
          </Segment>
          </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
    
    )
}

function mapStateToProps(state: RootState) {
  return {
    user: state.auth.user,
    app: state.app,
    alert: state.alert,
    mycdp_uri: state.auth.mycdp_uri,
  };
}

export default connect(mapStateToProps)(Template);