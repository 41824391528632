import React, {useEffect, useState} from "react"
import Template from "./Template"
import {useActions} from "../redux/actions";
import * as ReportsActions from "../redux/actions/reports";
import {Button, Dropdown, Grid, Icon, Input} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import moment from "moment";
import {brands} from "../constants";
import csvDownload from 'json-to-csv-export'
import HelpMark from "./help/HelpMark";

declare var google: any
const NewPatientOrigin: React.FC<any> = (props) => {
    const initialFilters: any = {
        practice: ['all'],
        brand: 'all',
        date: [moment().format('YYYY-MM')],
    }
    const reportsActions = useActions(ReportsActions);
    const [filters, setFilters] = useState(initialFilters);
    const [expandableColumn, setExpandableColumn] = useState('');
    const [expandableColumn2ndLevel, setExpandableColumn2ndLevel] = useState('');
    const [practiceOptions, setPracticeOptions] = useState([]);
    const [dateOptions, setDateOptions] = useState([]);

    google.charts.load('current', {'packages':['corechart', 'bar']});
    const patientsData = new Array();
    useEffect(() => {
        reportsActions.getPatientOriginReport(filters);
    }, [filters]);

    useEffect(() => {
        reportsActions.fetchPracticeListByBrand(filters.brand)
            .then((list: { id: any; practice: string; }[]) => {
                const pracList: any = [{key: "all", value: "all", text: "All"}]
                list.forEach((item: { id: any; practice: any; }) => {
                    pracList.push({
                        key: item.id, value: item.practice, text: item.practice
                    })
                })
                setPracticeOptions(pracList);
            });
        const datesArr: any = [...new Array(24)].map((_, i) => moment().subtract(i, 'M').format('YYYY-MM')).map(item => ({key: item, value: item, text: item}));
        setDateOptions(datesArr)
    }, [filters.brand]);

    const expandRow1stLevel = (rowId: string) => {
        if (expandableColumn === rowId) {
            setExpandableColumn('');
        } else {
            setExpandableColumn(rowId);
        }
    }

    const expandRow2ndLevel = (rowId: string) => {
        if (expandableColumn2ndLevel === rowId) {
            setExpandableColumn2ndLevel('');
        } else {
            setExpandableColumn2ndLevel(rowId);
        }
    }

    const drawPatientOriginChart = () => {
                const {newPatientOriginReport} = props;
                const chartData = new Array();
                chartData.push(["Label", "Count"])
                const total : any = {};
                Object.keys(newPatientOriginReport).forEach(function (date: string) {
                    Object.keys(newPatientOriginReport[date]).forEach(function (label: string) {
                        if (label === 'No Entry') {
                              return;
                            }
                        total[label] = total[label] ? total[label] + newPatientOriginReport[date][label].total : newPatientOriginReport[date][label].total;
                    })
                })
                Object.keys(total).forEach((item:string) => {
                        const lead = [];
                        lead.push(item);
                        lead.push(total[item]);
                        chartData.push(lead);
                })
        const data = google.visualization.arrayToDataTable(chartData);
                const options = {
                        title: 'New Patient Origin',
                        legend: { position: 'right'},
                    hAxis: {
                            title: 'Count'
                        },
                    height:400,
                        width:400
                };

                const chart = new google.visualization.PieChart(document.getElementById('npo_pie_chart'));
                chart.draw(data, options);
            }
    const sortObject = (obj:any) => {
        const entries = Object.entries(obj);
        const sortedEntries = entries.sort((a:any, b:any) => b[1] - a[1]);
        const sortedObj = Object.fromEntries(sortedEntries);
        return sortedObj;
    }
    const getTotalTableData = (reportData:any) => {
        const totalTable = new Array();
        const tableRows: any = new Array();
        let total:any = {};
        let colmnTotalFinal = 0;
        Object.keys(reportData).forEach(function (date: string) {
            Object.keys(reportData[date]).forEach(function (label: string) {
                total[label] = total[label] ? total[label] + reportData[date][label].total : reportData[date][label].total;
                colmnTotalFinal += reportData[date][label].total;
            })
        })
        Object.keys(sortObject(total)).forEach((item:string) => {
            tableRows.push(<tr key={item}>
                <td>{item}</td>
                <td><b>{total[item]}</b></td>
                <td> <b>{((total[item]/ colmnTotalFinal) * 100).toFixed(2)}%</b></td>
            </tr>)
        })
        totalTable.push(
            <Grid.Column mobile={16} tablet={8} computer={4}>
                <div className="table-responsive tableFixHeadAndFirstTwoColumn">
                    <table className="ui table table-striped celled table-hover customTable unstackable">
                        <thead>
                        <tr>
                            <th className="cursorPointer">
                                <div>
                                    Source
                                </div>
                            </th>
                            <th className="cursorPointer">
                                <div>
                                    Total
                                </div>
                            </th>
                            <th className="cursorPointer">
                                <div>
                                    Percentage
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableRows}
                        </tbody>
                    </table>
                </div>
            </Grid.Column>
        )
        return totalTable;
    }
    const getTableData = (reportData:any) => {

        const tables = new Array<JSX.Element>();

        reportData &&
        Object.keys(reportData).forEach((date) => {
            const tableRows: JSX.Element[] = [];
            const report = reportData[date];
            let colmnTotal = 0;

            // Calculate colmnTotal before generating rows
            Object.keys(report).forEach((label) => {
                colmnTotal += report[label].total;
            });

            Object.keys(report).forEach((label, mainIndex) => {
                const clickLabel = date + label;
                const levelOneIcon = expandableColumn === clickLabel ? 'minus' : 'plus';

                tableRows.push(
                    <tr key={label}>
                        <td>
                            <Icon
                                onClick={() => expandRow1stLevel(clickLabel)}
                                className={`${levelOneIcon} red`}
                                size="small"
                            />
                            {label}
                        </td>
                        <td><b>{report[label].total}</b></td>
                        <td><b>{((report[label].total / colmnTotal) * 100).toFixed(2)}%</b></td>
                    </tr>
                );

                Object.keys(report[label]).forEach((practice, index) => {
                    if (practice !== 'total') {
                        const secondLevelIcon =
                            expandableColumn2ndLevel === practice ? 'minus' : 'plus';

                        if (expandableColumn === clickLabel) {
                            tableRows.push(
                                <tr key={practice}>
                                    <td>
                                        <Icon
                                            onClick={() => expandRow2ndLevel(practice)}
                                            className={`${secondLevelIcon} red ml20`}
                                            size="small"
                                        />
                                        {practice}
                                    </td>
                                    <td>{report[label][practice].length}</td>
                                </tr>
                            );
                        }

                        report[label][practice].forEach((item: { PatNum: number; FirstVisit: string; }, i: any) => {
                            const patient = {
                                practice,
                                PatNum: item.PatNum,
                                FirstVisit: item.FirstVisit,
                                source: label,
                            };
                            patientsData.push(patient);

                            if (
                                expandableColumn2ndLevel === practice &&
                                expandableColumn === clickLabel
                            ) {
                                tableRows.push(
                                    <tr key={`${mainIndex}-${index}-${i}`}>
                                        <td>
                                            <p className="ml50">{item.PatNum}</p>
                                        </td>
                                        <td></td>
                                    </tr>
                                );
                            }
                        });
                    }
                });
            });

            tables.push(
                <Grid.Column mobile={16} tablet={8} computer={4} key={date}>
                    <div className="table-responsive tableFixHeadAndFirstTwoColumn">
                        <table className="ui table table-striped celled table-hover customTable unstackable">
                            <thead>
                            <tr>
                                <th className="cursorPointer">
                                    <div>Source</div>
                                </th>
                                <th className="cursorPointer">
                                    <div>{date}</div>
                                </th>
                                <th className="cursorPointer">
                                    <div>Percentage</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>{tableRows}</tbody>
                        </table>
                    </div>
                </Grid.Column>
            );
        });

        return tables;
    };

    const {newPatientOriginReport} = props;
    newPatientOriginReport && google.charts.setOnLoadCallback(drawPatientOriginChart);
    const dataToConvert = {
        data: patientsData,
        filename: 'patient_origin_report',
        delimiter: ',',
        headers: ['Practice','Patient Number','First Visit Date','Result']
    }
    return (
        <Template activeLink="new-patient-origin">
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column columns={16}>
                                <h3 className="left floated">New Patient Origin <HelpMark pageId='1'/></h3>
                                <div className="topFilters">
                                    <Button
                                        className="mb15 mr10"
                                        primary={true}
                                        onClick={()=> csvDownload(dataToConvert)}
                                    > Download</Button>
                                    <Dropdown
                                        placeholder="Select Brand"
                                        className='mr10 mb15'
                                        selection={true}
                                        defaultValue={filters.brand}
                                        options={[
                                            ...brands
                                        ]}
                                        onChange={(_event, data) => {
                                            setFilters({...filters, brand: data.value});
                                        }}
                                    />
                                    <Dropdown
                                        search={true}
                                        className='mr10 mb15'
                                        name="locations"
                                        placeholder="Practice"
                                        selection={true}
                                        multiple={true}
                                        defaultValue={filters.practice}
                                        options={practiceOptions}
                                        onChange={(_event, data) => {
                                            setFilters({...filters, practice: data.value});
                                        }}
                                    />
                                    <Dropdown
                                        search={true}
                                        className='mb15'
                                        name="date"
                                        placeholder="Select Date"
                                        selection={true}
                                        multiple={true}
                                        options={dateOptions}
                                        defaultValue={filters.date}
                                        onChange={(_event, data: any) => {
                                            setFilters({...filters, date: data.value});
                                        }}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    <Grid>
                        <Grid.Row>
                            {newPatientOriginReport && getTableData(newPatientOriginReport)}
                            {newPatientOriginReport && getTotalTableData(newPatientOriginReport)}
                        </Grid.Row>
                    </Grid>

                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <div id={'npo_pie_chart'}>

                    </div>
                </div>
            </div>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        newPatientOriginReport: state.reports.newPatientOriginReport,
        loader: state.app.loader
    };
}

export default connect(mapStateToProps)(NewPatientOrigin);
