import React from "react"
import {Redirect,Route} from "react-router"
import {PrivateRoute, PrivateRouteProps} from "./components/PrivateRoute"
import SignIn from "./components/SignIn"
import Test from "./components/Test"
import {history} from "./redux/configure";
import Dashboard from "./components/Dashboard";
import Members from "./components/Members";
import MembersStatusReport from "./components/MembersStatusReport"
import CallRecordings from "./components/CallRecordings";
import ContactDataReport from "./components/ContactDataReport";
import QualityIndexReport from "./components/QualityIndexReport";
import PriorityStatusReport from "./components/PriorityStatusReport";
import LeadDataReport from "./components/LeadDataReport";
import {useActions} from "./redux/actions";
import * as Actions from "./redux/actions/usage";
import * as authActions from './redux/actions/auth';
import { getCookie, setCookie } from "./constants";
import { validObj } from "./utils/arrayUtils";
import NewPatientOrigin from "./components/NewPatientOrigin";
import PageUnderConstruction from "./components/underConstruction/pageUnderConstruction";
import UniversalUsageReport from './components/UniversalUsageReport';
import * as UserSetupActions from "./redux/actions/userSettings";
import {SetupFields} from "./enums/userSetupField";
import {SetupAppName} from "./enums/userSetupAppNames";

const AppRoutes:React.FC<any>=(props)=>{
    const getcookie = getCookie('cdp-cookie');
    const cookie: any = getcookie ? JSON.parse(getcookie) : null;
    const authAction = useActions(authActions);
    const userSetupActions = useActions(UserSetupActions);
    if (validObj(cookie) && !validObj(props.auth)) {
        authAction.cdpwebTokenLogin(cookie);
    }

    const isAuthenticated = !!(props.auth && props.auth.user && props.auth.user.id);
    const defaultProtectedRouteProps: PrivateRouteProps = {
        isAuthenticated: isAuthenticated,
        authenticationPath: "/"
    };
    const usageActions = useActions(Actions);

    let defaultPage:any =props.auth && props.auth.user && props.auth.user.defaultPage?props.auth.user.defaultPage:"/dashboard";
    const queryParams :any= new URLSearchParams(window.location.search);
    const page = queryParams.get("page")?"/members/all":defaultPage;

    if (isAuthenticated) {
        const value = {
            email: props.auth.user.email,
            givenName: props.auth.user.firstname,
            imageUrl: props.auth.user.profilePicture,
        }
        setCookie('cdp-cookie', JSON.stringify(value), 7);
    }
    isAuthenticated&& history.listen((location)=>{
        const getStatusParams={
            field: SetupFields.Practice,
            appId: SetupAppName.CALL_CENTER,
            userId: props.auth.user.id
        }
        userSetupActions.getSetup(getStatusParams).then((response: any) => {
            if(props && props.auth && props.auth.user){
                const {id} = props.auth.user;
                const {pathname} = location;
                const {accessToken} = props.auth;
                const {practice}=response;
                const usagePayLoad  = {userId:id,defualtPracticeId:practice,visitedUrl:`callcenter.cdp.dental${pathname}`,token:accessToken}
                    setTimeout(function() {
                        usageActions.saveApplicationUsage(usagePayLoad)
                },3000)
            }
        })
    })
    const RedirectTo=(prop:any)=>isAuthenticated?<Redirect to={page}/> : <SignIn {...prop} />

    return (
        <>
          <Route
            exact={true}
            path="/"
            render={props=>RedirectTo(props)}
            />
            <Route
                exact={true}
                path="/cdpLogin/:token"
                component={Members}
            />
               <Route
                exact={true}
                path="/login"
                component={Members}
            />
          <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/dashboard"
                component={Dashboard}
            />
          <PrivateRoute
              {...defaultProtectedRouteProps}
              exact={true}
              path="/under-construction"
              component={PageUnderConstruction}
          />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/members-status-report"
                component={MembersStatusReport}
            />
          <PrivateRoute
            {...defaultProtectedRouteProps}
            exact={true}
            path="/call-recordings"
            component={CallRecordings}
          />
          <PrivateRoute
            {...defaultProtectedRouteProps}
            exact={true}
            path="/new-patient-origin"
            component={NewPatientOrigin}
          />
          <PrivateRoute
            {...defaultProtectedRouteProps}
            exact={true}
            path="/contact-data-report"
            component={ContactDataReport}
          />
          <PrivateRoute
            {...defaultProtectedRouteProps}
            exact={true}
            path="/quality-index-report"
            component={QualityIndexReport}
          />
          <PrivateRoute
            {...defaultProtectedRouteProps}
            exact={true}
            path="/priority-status-report"
            component={PriorityStatusReport}
          />
          <PrivateRoute
            {...defaultProtectedRouteProps}
            exact={true}
            path="/lead-data-report"
            component={LeadDataReport}
          />
            
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/members/smile-magic"
                component={Members}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/members/family-smiles"
                component={Members}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/members/all"
                component={Members}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/deleted-appointments/bygroup"
                component={Test}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/deleted-appointments/summary"
                component={Test}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/deleted-appointments/details"
                component={Test}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/usage"
                component={UniversalUsageReport}
            />
        </>
    )
}
export default AppRoutes
