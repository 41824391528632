import React, {useEffect, useState} from "react";
import {Button, Modal, Dropdown, Grid, Form} from "semantic-ui-react";
import {useActions} from "../redux/actions";
import * as practiceActions from "../redux/actions/practice";
import {membersStatusFilters} from "../constants";

const DefaultPracticeModal : React.FC<any> = (props: any) => {
  const initialState = {
    practiceOptions: []
  };
  const [state, setStates] = useState(initialState);
  const practiceAction = useActions(practiceActions);
  useEffect(() => {
    practiceAction.getPractices().then(loadPractices)
  }, []);

  const loadPractices = (practices: any[]) => {
    let practiceOptions: any = practices.map((practice, key) => ({
      text: practice.practice,
      value: practice.practiceabbr,
      key
    }));
    practiceOptions = [
      {text: 'Support', value: 'Support', key: practiceOptions.length + 1},
      ...practiceOptions
    ];
    setStates({...state, practiceOptions})
  }

  return (
    <Modal className={'modalCenter'}
           onClose={() => props.handleClose()}
           open={true}
    >
    <Modal.Header content='Default Practice' />
      <Modal.Content scrolling={false}>
        <Form className="formStyle mt10">
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <label className="mr10">Select Default Practice</label>
                <Dropdown
                  search={true}
                  className='mr10'
                  fluid
                  placeholder='Select Practice'
                  selection
                  options={state.practiceOptions}
                  onChange={props.onChangeDefaultSetup}
                  defaultValue={props.defaultValues.memberPractice}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label>Filter By Status</label>
                <Dropdown
                  placeholder="Select Status"
                  fluid
                  selection
                  defaultValue={props.defaultValues.memberStatus}
                  options={[
                    { key: "All", value: "", text: "All" },
                    ...membersStatusFilters,
                  ]}
                  onChange={(event, data) => {
                    props.setStatusFilter(data.value as string);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="OK"
          primary={true}
          onClick={() => props.onSaveSetup()}
        />
      </Modal.Actions>
    </Modal>
  );
}


export default DefaultPracticeModal;
