import React, { useState,useEffect} from "react"
import GoogleLogin from "react-google-login"
import CookieModal from "./CookieModal"
import {useActions} from "../redux/actions";
import * as Actions from "../redux/actions/auth";
import {Grid} from "semantic-ui-react";




const SignIn:React.FC<any>=(_props)=>{
    const [cookieModal,setCookieModal] = useState(false)
    const authActions=useActions(Actions)
    useEffect(()=>{
      const queryParams :any= new URLSearchParams(window.location.search);
      const accessToken = queryParams.get("token");
        const page = queryParams.get("page")?"members/all":"";
      if (accessToken && accessToken.length > 0) {
           authActions.cdpLogin(accessToken,page)
      }
    },[authActions])

    const doLogin = async (data: any) => {
      await authActions.LogIn(data.profileObj);
  };
  const onFailure = (response: any) => new Promise((_resolve, _reject) => {
    if (response && response.error === 'idpiframe_initialization_failed') {
        setCookieModal(true);
    }
});
const closeCookieModal = () => {
  setCookieModal(false);
}
return (
        <div className="login">
        <div className="contentCenter container-fluid">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={10}><div className='sectionLeft'/></Grid.Column>
                    <Grid.Column width={6}>
                        <img src="/images/logoMain.png" className="align-self-center loginLogo p-3 mt30" alt="Logo"/>
                        <h1 className="ui header inverted mt-0 mb-2">SIGN IN</h1>
                        <GoogleLogin
                            clientId="534451231648-d2a526h66llkn1e781gv6fgjvofsph2c.apps.googleusercontent.com"
                            buttonText="Sign in with Google"
                            className ="google"
                            onSuccess={doLogin}
                            onFailure={onFailure}
                        />
                        { cookieModal && <CookieModal handleClose={closeCookieModal}/>}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    </div>
  );
}
export default SignIn