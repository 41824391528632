import Fuse from 'fuse.js'
import { useCallback,useMemo,useState } from 'react'
import {debounce} from 'throttle-debounce'


export const useFuse=(list: any[],options:any)=>{
    const [query,updateQuery]=useState('')

    const {limit,matchAllOnEmptyQuery,...fuseOptions}=options

    const fuse=useMemo(
        ()=>new Fuse(list,fuseOptions),
        [list,fuseOptions]
    )

    const hits=useMemo(
        ()=>!query && matchAllOnEmptyQuery
         // @ts-ignore
        ? fuse.getIndex().docs.slice(0,limit).map((item: any,refIndex: any)=>({item,refIndex}))
        : fuse.search(query,{limit}),
        [fuse,limit,matchAllOnEmptyQuery,query]
    )

    const setQuery=useCallback(
        debounce(100,updateQuery),
        []
    )

    const onSearch = useCallback(
        (e) => setQuery(e.target.value.trim()),
        [setQuery]
      )
    
      return {
        hits,
        onSearch,
        query,
        setQuery,
      }
}