import { Action, ActionType } from "../../model/actionType";
import { CallRecordings } from "../../model/callRecordings";
import createReducer from "./createReducer";
const initialState = {
  callRecordings: [],
  callDirectory : []
};

export const callRecordings = createReducer(initialState, {
  [ActionType.LOAD_CALL_DIRECTORY](state: CallRecordings, action: Action<CallRecordings>) {
    return Object.assign({ ...state }, action.payload);
  },
  [ActionType.LOAD_CALL_DATES](state: CallRecordings, action: Action<CallRecordings>) {
    return Object.assign({ ...state }, action.payload);
  },
  [ActionType.LOAD_CALL_RECORDINGS](state: CallRecordings, action: Action<CallRecordings>) {
    return Object.assign({ ...state }, action.payload);
  },
  [ActionType.DOWNLOAD_CALL_RECORDING](state: CallRecordings, action: Action<CallRecordings>) {
    return Object.assign({ ...state }, action.payload);
  },
  [ActionType.LOAD_SEARCH_BY_TEXT](state: CallRecordings, action: Action<CallRecordings>) {
    return Object.assign({ ...state }, action.payload);
  }
});
