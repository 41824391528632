import React, {useEffect, useState} from "react"
import Template from "./Template"
import {useActions} from "../redux/actions";
import * as QualityIndexReportActions from "../redux/actions/reports";
import {Button, Grid, Table} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import moment from "moment";
import HelpMark from "./help/HelpMark";
import Timer from "./Timer";

declare var google: any

const QualityIndexReport: React.FC<any> = (props) => {
    const initialState: any = {
        weeklyleadStatus: {},
        monthleadStatus: {},
        qualityWon: {},
        qualityMonthWon: {},
        qualityWonToday: {},
        qualityLost: {},
        qualityMonthLost: {},
        leadsMonth: {},
        lostLeads: {},
        leadsConversion: {},
        leadsConversionWeekly: {},
        lostLeadsWeekly: {},
        sales_funnel: {},
        finalTime: ''
    }

    google.charts.load('current', {'packages': ['corechart', 'bar']});
    const qualityIndexReportActions = useActions(QualityIndexReportActions);
    const [state, setStates] = useState(initialState);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        loadData();
    }, []);


    const loadData = () => {
        setLoader(true);

        const date = new Date();
        const d1 = new Date();
        d1.setHours(0, 0, 0, 0);
        const d2 = new Date();
        d2.setHours(23, 59, 59);
        const seven = new Date(new Date().setDate(new Date().getDate() + 7));
        seven.setHours(0, 0, 0, 0);
        const yesterday1 = new Date(new Date().setDate(new Date().getDate() - 1));
        yesterday1.setHours(0, 0, 0, 0);
        const yesterday2 = new Date(new Date().setDate(new Date().getDate() - 1));
        yesterday2.setHours(23, 59, 59);
        const thirty = new Date(new Date().setDate(new Date().getDate() - 30));
        thirty.setHours(0, 0, 0, 0);
        const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        thisMonth.setHours(0, 0, 0, 0);
        const first = date.getDate() - date.getDay();
        const last = first + 6;
        const weekStart = new Date(new Date().setDate(first));
        weekStart.setHours(0, 0, 0, 0);
        const weekEnd = new Date(new Date().setDate(last));
        weekEnd.setHours(23, 59, 59);
        const dates = {
            d1: d1.toISOString(),
            d2: d2.toISOString(),
            yesterday1: yesterday1.toISOString(),
            yesterday2: yesterday2.toISOString(),
            thirty: thirty.toISOString(),
            seven: seven.toISOString(),
            thisMonth: thisMonth.toISOString(),
            weekStart: weekStart.toISOString(),
            weekEnd: weekEnd.toISOString()
        };

        qualityIndexReportActions.getQualityIndexReports(dates)
            .then((res: any) => {
                setLoader(false);
            });
    }

    const drawWeeklyleadStatus = () => {
        const data = google.visualization.arrayToDataTable(state.weeklyleadStatus);
        const options = {
            title: 'Won Vs Lost',
            legend: {position: "bottom"},
            hAxis: {
                title: 'Record Count'
            },
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.PieChart(document.getElementById('won_lost_week'));
        chart.draw(data, options);
    }
    const drawMonthleadStatus = () => {

        const data = google.visualization.arrayToDataTable(state.monthleadStatus);
        const options = {
            title: 'Won Vs Lost',
            legend: {position: 'bottom'},
            hAxis: {
                title: 'Record Count'
            },
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.PieChart(document.getElementById('won_lost_month'));
        chart.draw(data, options);
    }
    const drawLostLeads = () => {

        const data = google.visualization.arrayToDataTable(state.lostLeads);
        const options = {
            title: 'Lost Leads',
            legend: {position: 'right'},
            hAxis: {
                title: 'Record Count'
            },
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.PieChart(document.getElementById('lost_leads'));
        chart.draw(data, options);
    }
    const drawLostLeadsWeekly = () => {

        const data = google.visualization.arrayToDataTable(state.lostLeadsWeekly);
        const options = {
            title: 'Lost Leads This Week',
            legend: {position: 'right'},
            hAxis: {
                title: 'Record Count'
            },
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.PieChart(document.getElementById('lost_leads_weekly'));
        chart.draw(data, options);
    }
    const drawSales_funnel = () => {
        const data = google.visualization.arrayToDataTable(state.sales_funnel);
        const options_stacked = {
            title: 'Sales Funnel',
            width: '100%',
            height: 400,
            isStacked: true,
            vAxis: {
                title: "Record Counts"
            },
            hAxis: {
                title: "Location Preference",
                viewWindowMode: 'pretty',
                slantedText: true
            },
            legend: {
                position: 'right'
            },
            chartArea: {
                left: 100,
                width: '60%',
                height: '55%'
            }
        };

        const chart = new google.visualization.ColumnChart(document.getElementById('sales_funnel'));

        chart.draw(data, options_stacked);
    }
    const drawLeadsConversion = () => {

        const data = google.visualization.arrayToDataTable(state.leadsConversion);
        const options = {
            title: 'Conversion Percentage',
            legend: {position: 'bottom'},
            hAxis: {
                title: 'Record Count'
            },
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.PieChart(document.getElementById('leads_conversion'));
        chart.draw(data, options);
    }
    const drawLeadsConversionWeekly = () => {

        const data = google.visualization.arrayToDataTable(state.leadsConversionWeekly);
        const options = {
            title: 'Conversion Percentage',
            legend: {position: 'bottom'},
            hAxis: {
                title: 'Record Count'
            },
            height: 400,
            width: '100%'
        };

        const chart = new google.visualization.PieChart(document.getElementById('leads_conversion_weekly'));
        chart.draw(data, options);
    }

    const {reports} = props;

    if (reports) {
        state.finalTime = moment().format('h:mm a')
        state.weeklyleadStatus = reports.weeklyleadStatus;
        state.monthleadStatus = reports.monthleadStatus;
        state.qualityWon = reports.qualityWon;
        state.qualityMonthWon = reports.qualityMonthWon;
        state.qualityWonToday = reports.qualityWonToday;
        state.qualityLost = reports.qualityLost;
        state.qualityMonthLost = reports.qualityMonthLost;
        state.leadsMonth = reports.leadsMonth;
        state.lostLeads = reports.lostLeads;
        state.leadsConversion = reports.leadsConversion;
        state.leadsConversionWeekly = reports.leadsConversionWeekly;
        state.lostLeadsWeekly = reports.lostLeadsWeekly;
        state.sales_funnel = reports.sales_funnel;


        google.charts.setOnLoadCallback(drawWeeklyleadStatus);
        google.charts.setOnLoadCallback(drawMonthleadStatus);
        google.charts.setOnLoadCallback(drawLostLeads);
        google.charts.setOnLoadCallback(drawLeadsConversion);
        google.charts.setOnLoadCallback(drawLeadsConversionWeekly);
        google.charts.setOnLoadCallback(drawLostLeadsWeekly);
        google.charts.setOnLoadCallback(drawSales_funnel);
    }

    const tableHeader = () => {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell><div>Location Preference</div></Table.HeaderCell>
                    <Table.HeaderCell><div>Record Count</div></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    const getTableData = (items: any, cell1: string, cell2: string) => {
        return (
            <Table className="ui table table-striped celled table-hover customTable unstackable">
                {tableHeader()}

                <Table.Body>
                    {items && items.map(function (leads: any) {
                        return (
                            <Table.Row>
                                <Table.Cell>{leads.practices && leads.practices[cell1]}</Table.Cell>
                                <Table.Cell>{leads[cell2]}</Table.Cell>
                            </Table.Row>
                        )
                    })
                    }
                </Table.Body>
            </Table>
        )
    }


    return (

        <Template activeLink="quality-index-report">
            
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div className="float-left mb10">
                                    <h3 className="mb0">Quality Index Report<HelpMark pageId='1'/></h3>
                                    <strong className="f12">{'As of today at ' + state.finalTime}</strong>
                                </div>
                                <div className="topFilters flex items-end flex-wrap">
                                    <Button className='ui primary button mb15' onClick={() => window.print()}>
                                        Print
                                    </Button>
                                    <Button className='ui primary button ml10 mb15' onClick={() => loadData()}>
                                        Refresh
                                    </Button>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            <div className="flex flex-wrap items-stretch dataReports">
                <div>
                    <h4>This Month</h4>
                    <div id={'lost_leads'}/>
                </div>

                <div>
                    <h4>This Week | This represents all closed leads</h4>
                    <div id={'won_lost_week'}/>
                </div>

                <div>
                    <h4>This Month</h4>
                    <div id={'won_lost_month'}/>
                </div>

                <div>
                    <h4>Leads Won Today</h4>
                    {getTableData(state.qualityWonToday, 'practice', 'leadsWon')}
                </div>

                <div>
                    <h4>Leads Won This Week</h4>
                    {getTableData(state.qualityWon, 'practice', 'leadsWon')}
                </div>

                <div>
                    <h4>Leads Won MTD</h4>
                    {getTableData(state.qualityMonthWon, 'practice', 'leadsWon')}
                </div>

                <div>
                    <h4>Lost Leads - Details (This Week)</h4>
                    {getTableData(state.qualityLost, 'practice', 'leadsLost')}
                </div>

                <div>
                    <h4>Lost Leads - Details (This Month)</h4>
                    {getTableData(state.qualityMonthLost, 'practice', 'leadsLost')}
                </div>

                <div>
                    <h4>Completed Leads - Detail (This Month)</h4>
                    {getTableData(state.leadsMonth, 'practice', 'leads')}
                </div>

                <div>
                    <h5>This Month | Shows the number of leads we win once we are able to talk to them. Lost leads based
                        on
                        Attempts completed and No Answer are excluded</h5>
                    <div id={'leads_conversion'}/>
                </div>

                <div>
                    <h5>This Week | Shows the number of leads we win once we are able to talk to them. Lost leads based
                        on
                        Attempts completed and No Answer are excluded</h5>
                    <div id={'leads_conversion_weekly'}/>
                </div>

                <div>
                    <h4>This Week</h4>
                    <div id={'lost_leads_weekly'}/>
                </div>

                <div>
                    <h4>Current | Shows the amount of calls that are for today or prior and haven't been made</h4>
                    <div id={'sales_funnel'}/>
                </div>
            </div>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        reports: state.reports.qualityIndexReport
    };
}

export default connect(mapStateToProps)(QualityIndexReport);
