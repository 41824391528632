import React, {useEffect, useState} from "react";
import Template from "./Template";
import { Grid } from "semantic-ui-react";
import HelpMark from "./help/HelpMark";
import {useActions} from "../redux/actions";
import * as MembersActions from "../redux/actions/members";
import { myCDPOpenIssuesApplicationList } from '../constants';

const Dashboard: React.FC<any> = (props) => {

    const membersActions = useActions(MembersActions);

    const [loader, setLoader] = useState(false);
    const [isThereOpenIssues, setIsThereOpenIssues] = useState(false);

    useEffect(() => {
        setLoader(true);

        setTimeout(function() {
            checkOpenIssues();
        }, 1000)
    });

    const checkOpenIssues = async() => {
        const result: any = await membersActions.getOpenIssues(myCDPOpenIssuesApplicationList);
        setIsThereOpenIssues(result.length > 0 ? true : false);
    }

    return(
        <Template activeLink="dashboard">
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div className="float-left mb10">
                                    <h3 className="mb0">Dashboard<HelpMark pageId='1' isThereOpenIssues={{status: isThereOpenIssues, openIssues:myCDPOpenIssuesApplicationList}} /></h3>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>

        </Template>
    );
}

export default Dashboard;