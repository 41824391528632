import {ActionType} from "../../model/actionType"
import {errorHandler} from "./auth"

export const getQualityIndexReports = (dates: any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `report/quality-index-report`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.post(url, JSON.stringify(dates))
        dispatch({ type: ActionType.LOAD_QUALITY_INDEX_REPORT, payload: {reports : resp } });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const getContactDataReport = () => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `report/contact-data-report`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {

        const resp = await api.get(url)
        dispatch({ type: ActionType.LOAD_CONTACT_DATA_REPORT, payload: {reports : resp } });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const getPriorityStatusReport = () => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `report/priority-status-report`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.get(url)
        dispatch({ type: ActionType.LOAD_PRIORITY_STATUS_REPORT, payload: {reports : resp } });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const getLeadDataReport = (dates: any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `report/lead-data-report`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.post(url, JSON.stringify(dates))
        dispatch({ type: ActionType.LOAD_LEAD_DATA_REPORT, payload: {reports : resp } });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;
    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const getLeadDataReportByDates = (dates: any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `report/lead-data-report`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.post(url, JSON.stringify(dates))
        dispatch({ type: ActionType.LOAD_LEAD_DATA_REPORT_BY_DATE, payload: resp && resp.leadsEntered });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;
    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const GetRegions=()=>async(dispatch:Function,getState:Function,api:any)=>{
    const url=`report/regionsList`
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp=await api.get(url)
        dispatch({
            type:ActionType.LOAD_REGIONS,
            payload:{
                regionsList:resp.regionList
            }
        })
        dispatch({type:ActionType.LOADER,payload:false})
        return resp
    }catch(err){
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const GetPracticeList=()=>async(dispatch:Function,getState:Function,api:any)=>{
    const url=`report/practicesList`
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp=await api.get(url)
        dispatch({
            type:ActionType.LOAD_PRACTICELIST,
            payload:{
                practiceList:resp.practicesList
            }
        })
        dispatch({type:ActionType.LOADER,payload:false})
        return resp
    }catch(err){
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const getPatientOriginReport = (params: string) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const param = JSON.stringify(params);
        const url = `report/new-patient-origin?params=${param}`;
        const resp = await api.get(url);
        dispatch({ type: ActionType.LOAD_NEW_PATIENT_ORIGIN_REPORT, payload:  resp  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch (error) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(error));
    }
}

export const fetchPracticeListByBrand = (brand:string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const practiceList = await api.get(`practice/list/bybrand/${brand}`)
        return practiceList;
    } catch (err) {
        return dispatch(errorHandler(err))
    }
}

export const fetchPracticeListByRegion = (region:string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const practiceList = await api.get(`practice/list/byregion/${region}`)
        return practiceList;
    } catch (err) {
        return dispatch(errorHandler(err))
    }
}

export const sendMembersReport = (data:any) => async (dispatch: Function, getState: Function, api: any) => {
    const url = `report/sendMembersReport`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.post(url, {data : JSON.stringify(data)})
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;
    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}
