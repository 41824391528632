import {ActionType} from "../../model/actionType"
import {errorHandler} from "./auth"
export const getCallDirectory = () => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `call-recordings/directoryList`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {

        const resp = await api.get(url)
        dispatch({ type: ActionType.LOAD_CALL_DIRECTORY, payload: {callDirectory : resp } });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}


export const fetchCallDates = (practice: string) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `call-recordings/directoryList?practice=${practice}`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {

        const resp = await api.get(url)
        dispatch({ type: ActionType.LOAD_CALL_DATES, payload: {callDates : resp } });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const fetchCallRecords = (practice: string,campaign: string, date: any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `call-recordings/directoryList?practice=${practice}&campaign=${campaign}&dateFolder=${date}`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {

        const resp = await api.get(url)
        dispatch({ type: ActionType.LOAD_CALL_RECORDINGS, payload: {callRecordings : resp } });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const downloadRecording = (key: string, title: any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `call-recordings/downloadCallRecording?key=${key}&title=${title}`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {

        const resp = await api.get(url)
        dispatch({ type: ActionType.DOWNLOAD_CALL_RECORDING, payload: {url : resp.data } });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const searchByText = (text: string) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `call-recordings/search?text=${text}`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {

        const resp = await api.get(url)
        dispatch({ type: ActionType.LOAD_SEARCH_BY_TEXT, payload: {callRecordings : resp } });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

/**
 * Fetch patient origin report of practices
 * @param params (current date)
 * @returns Patient Origin List
 */

