/* eslint-disable*/
import _ from 'lodash';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import {connect} from 'react-redux';
import {Button, Dimmer, Input, Loader, Popup, Segment} from 'semantic-ui-react';

import {RootState} from '../model';
import {useActions} from '../redux/actions';
import * as Actions from '../redux/actions/universalUsageReport';
import * as practicesActions from '../redux/actions/practice';
import Template from './Template';
import HelpMark from "./help/HelpMark";

function UniversalUsageReport(props: any) {

    const initialState = {
        data: [],
        direction: 'sort',
        allowEdit: !!(props.user && props.user.isAdmin),
        order: true,
        column: null,
        startDate: new Date(moment().startOf('month').toString()),
        endDate: new Date(moment().endOf('month').toString()),
        searchKeyword: '',
        tempData: [],
        TableData: [[{title: 'No Records Found'}]],
        showHidden: false,
        actualData: [],
        userSearch: '',
        practices: []

    }
    let timeout: any = 0;
    const [state, setState] = useState(initialState);
    const [loader, setLoader] = useState(false);
    const UniversalUsageReportActions = useActions(Actions);
    const practiceAction = useActions(practicesActions);
    useEffect(() => fetchData(), [])

    const fetchData = () => {
        practiceAction.getPractices().then((practices: any) => {
            UniversalUsageReportActions.getUniversalUsageReport(moment(state.startDate).format('YYYY-MM-DD'), moment(state.endDate).format('YYYY-MM-DD'), userSearch).then((data: any) => {
                const actualData = data.length ? data.filter((item: any) => item['universalUsageTable.visitedUrl'] && item['universalUsageTable.visitedUrl'].includes('callcenter')) : []
                const TableData = pushTableData(actualData, state.showHidden, practices);
                setState({...state, data: data, tempData: data, TableData, actualData, practices: practices});
            })
        })

    }
    const handleSort = (clickedColumn: any) => {
        let {column, TableData, direction} = state;
        if (column !== clickedColumn) {
            column = clickedColumn;
            TableData = _.sortBy(TableData, o => o[clickedColumn]);
            direction = 'sort ascending';
            setState({...state, TableData: TableData.slice(), column, direction});
            return;
        }
        TableData = TableData.reverse();
        direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
        setState({...state, TableData: TableData.slice(), direction, order: !order});
    }
    const handleStartDateChange = (date: any) => {
        setState({...state, startDate: date})


    }
    const handleEndDateChange = (date: any) => {
        setState({...state, endDate: date})
    }
    const onSearchChange = (e: any) => {
        const searchKeyword = e.target.value;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {


            if (searchKeyword.toLowerCase() === '') {
                const TableData = pushTableData(state.actualData, state.showHidden, state.practices);
                setState({...state, searchKeyword: searchKeyword, TableData: TableData});
            } else {
                const data = state.actualData && state.actualData.filter((element: any) => {
                    return (element.visitedUrl.toLowerCase().includes(searchKeyword.toLowerCase())
                    )
                });
                const TableData = pushTableData(data, state.showHidden, state.practices);
                setState({...state, searchKeyword: searchKeyword, TableData: TableData});
            }


            clearTimeout(timeout);
            setLoader(false)
        }, 500);
        setLoader(true)

    };

    function onSearchEnter(arg0: string) {
        setState({...state, userSearch: arg0});
        fetchData();
    }

    const pushTableData = (res: any, showHidden: any = false, practices: any) => {
        const TableData = new Array();

        res && res.map((item: any, key: any) => {
            TableData.push([
                <tr key={key}>
                    <td>
                        {item.userId}
                    </td>
                    <td>{item.visitedUrl}</td>
                    <td>{
                        <Popup
                            content={item.defualtPracticeId}
                            key={key + 'popup'}
                            header={'Practice'}
                            trigger={
                                <b>{item.defualtPracticeId !== null && practices.length ? practices.map((prac: any)=> item.defualtPracticeId === prac.practiceabbr ? prac.practice : '') : ''}</b>}
                        />
                    } </td>
                    <td>{item.username} </td>
                    <td>{item.firstname} </td>
                    <td>{item.lastname} </td>
                    <td>{item.accessDate} </td>
                    <td>{item.count} </td>


                </tr>
            ])
        })
        return TableData;
    };
    const {data, order, column, TableData} = state;
    let {userSearch} = state;
    const direction = order ? 'down' : 'up';

    return (
        <Template activeLink="universal-usage-report">
            <div className="calendar-container">
                <div className="ui card">
                    <div className="content pb0">
                        <h2 className="float-left">Universal Usage Report <HelpMark pageId='84'/></h2>
                        <div className="topFilters flex items-end flex-wrap">
                            <div className="filter d-InlineBlock float-left labelTop">
                                <label className="labelStyle displayBlock">Start Date</label>
                                <div className="datePicker">
                                    <DatePicker
                                        className="ui input"
                                        selected={state.startDate}
                                        onChange={(date) => handleStartDateChange(date)}
                                    />
                                </div>
                            </div>

                            <div className="filter d-InlineBlock float-left labelTop">
                                <label className="labelStyle displayBlock">End Date</label>
                                <div className="datePicker">
                                    <DatePicker
                                        className="ui input"
                                        selected={state.endDate}
                                        onChange={(date) => handleEndDateChange(date)}
                                    />
                                </div>
                            </div>
                            <Input
                                icon='search'
                                placeholder="Search Visited Page"
                                onChange={onSearchChange}
                            />

                            <Input
                                placeholder="Search User"
                                defaultValue={userSearch}
                                onKeyUp={(event: any) => {
                                    userSearch = event.target.value;
                                    if (event.keyCode === 13) {
                                        onSearchEnter(event.target.value as string);
                                    }
                                }}
                            />

                            <Button
                                primary
                                onClick={() => {
                                    onSearchEnter(userSearch)
                                }}
                            > Search
                            </Button>

                        </div>
                    </div>
                </div>
                <div className="ui card">
                    <div className="content">
                        <div className="FreezeTable">
                        <table className="ui table table-striped celled table-hover unstackable">
                            <thead>
                            <tr>
                                <th className={`cursorPointer  ${column === 'userId' ? 'active' : ''}`}
                                    onClick={() => handleSort('userId')}>
                                    <div>UserID <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer ${column === 'visitedUrl' ? 'active' : ''}`}
                                    onClick={() => handleSort('visitedUrl')}>
                                    <div>Visited Page <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer ${column === 'defualtPracticeId' ? 'active' : ''}`}
                                    onClick={() => handleSort('defualtPracticeId')}>
                                    <div>Default Practice <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer  ${column === 'username' ? 'active' : ''}`}
                                    onClick={() => handleSort('username')}>
                                    <div>User Name <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer  ${column === 'firstname' ? 'active' : ''}`}
                                    onClick={() => handleSort('firstname')}>
                                    <div>FirstName <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer ${column === 'lastname' ? 'active' : ''}`}
                                    onClick={() => handleSort('lastname')}>
                                    <div>LastName <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer  ${column === 'accessDate' ? 'active' : ''}`}
                                    onClick={() => handleSort('accessDate')}>
                                    <div>Access Date <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer  ${column === 'count' ? 'active' : ''}`}
                                    onClick={() => handleSort('count')}>
                                    <div>Count <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>


                            </tr>
                            </thead>

                            <tbody>
                            {
                                <Dimmer as={Segment} active={loader}>
                                    <Loader active>Loading</Loader>
                                </Dimmer>}
                            {
                                TableData.length !== 1 ? TableData && TableData.map((item: any, key: any) => {
                                    return item
                                }) : <tr><td>No Records</td></tr>
                            }
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        alert: state.alert,
        accessToken: state.auth.accessToken
    };
}

export default connect(mapStateToProps)(UniversalUsageReport);


