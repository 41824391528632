import React from "react"
import Template from "./Template"



const Test:React.FC<any>=()=>{
    return (
       <Template activeLink="test" >
           <h1>Test Component Here</h1>
       </Template>
    )
}

export default Test