import * as React from "react";
import {useState} from "react";
import helpIcon from '../../styles/images/help-icon.png';
import downTimeIcon from '../../styles/images/downtime-icon.svg';
import HelpPageModal from "./HelpPageModal";
import OpenIssuesPopUp from "../openissuestracker/openIssuesPopUp";

interface Props {
  pageId: string,
	isThereOpenIssues?:{status: boolean, openIssues: string[]}
}

function HelpMark(props: Props) {


  const initialState : any = {
    helpPageModal : false,
		openIssuesPopUpModal : false
  }

  const [state, setStates] = useState(initialState);

  const openHelpPage = () => {
    setStates({ helpPageModal: true });
  };

  const hideHelpPageModal = () => {
    setStates({ helpPageModal: false });
  };

  const openOpenIssuesPopUpModal = () => {
		setStates({ openIssuesPopUpModal: true });
	}

  const hideOpenIssuesPopUpModal = () => {
		setStates({ openIssuesPopUpModal: false });
	};

  return (
      <div className="helpMark">
        <span onClick={() => openHelpPage()}><img alt="help" className="cursorPointer" src={helpIcon} /></span>
			  {
            props.isThereOpenIssues 
            && props.isThereOpenIssues.status 
            && <span onClick={() => openOpenIssuesPopUpModal()}><img alt="help" className="cursorPointer" src={downTimeIcon} /></span>
        }

        { 
          state.helpPageModal &&
          <HelpPageModal hideHelpPageModal={hideHelpPageModal} pageId={props.pageId} />
        }
        { 
          state.openIssuesPopUpModal &&
          <OpenIssuesPopUp hideModal={hideOpenIssuesPopUpModal} applicationList={props.isThereOpenIssues?props.isThereOpenIssues.openIssues:[""]} />
        }
      </div>
  );
}

export default HelpMark;
