
export enum ActionType {
    //app-auth
    LOGIN_BEGIN,
    LOGIN_SUCCESS,
    LOGOUT,
    LOAD_SESSION,
    ALERT,
    LOADER,
    SEND_FEEDBACK,
    SAVE_USAGE,
    SIDEBAR_OPEN,
    //Members
    LOAD_MEMBERS,
    LOAD_DENTIST_LIST,
    LOAD_OPEN_ISSUES_LIST,
    
    SAVE_NOTES,
    SAVE_RESULT,
    LOAD_PRACTICES,
    // call recordings
    LOAD_CALL_DIRECTORY,
    LOAD_CALL_DATES,
    LOAD_CALL_RECORDINGS,
    DOWNLOAD_CALL_RECORDING,
    LOAD_SEARCH_BY_TEXT,
    LOAD_CONTACT_DATA_REPORT,
    LOAD_MYCDP_URI,
    LOAD_QUALITY_INDEX_REPORT,
    LOAD_PRIORITY_STATUS_REPORT,
    LOAD_LEAD_DATA_REPORT,
    LOAD_LEAD_DATA_REPORT_BY_DATE,
    LOAD_REGIONS,
    LOAD_PRACTICELIST,
    DEFAULT_SETUP,
    SAVE_APP_USAGE,
    SAVE_APP_USAGE_SUCCESS,
    LOAD_CRONS_LIST,
    LOAD_NEW_PATIENT_ORIGIN_REPORT,
    LOAD_MEMBERS_STATUS_REPORT,
    FETCH_UNIVERSAL_USAGE_REPORT_SUCCESS,
    FETCH_UNIVERSAL_USAGE_REPORT_FAILURE,

}



export interface Action<T> {
    type: ActionType;
    payload: T;
  }
  