import {ActionType} from "../../model/actionType";
import {errorHandler} from "./auth";


export const createSetup = (userSetup: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.post(`userSetup`, userSetup);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
}

export const getSetup = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    const paramObj = {...params, userId: getState().auth.user.id}
    try {
        const resp = await api.get(`userSetup/${JSON.stringify(paramObj)}`);
        dispatch({type: ActionType.LOADER,payload: {
            userSettings:resp
        }});
        return resp
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
}