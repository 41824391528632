import React, {useEffect, useState} from "react";
import {Button, Modal, Comment, Header, TextArea, Form} from "semantic-ui-react";
import moment from "moment";
import {useActions} from "../redux/actions";
import * as MembersActions from "../redux/actions/members";

const ResultsModal:React.FC<any>=(props)=>{
    const membersActions = useActions(MembersActions);
    const [results, setResults] = useState([]);

    useEffect(() => {
        membersActions.getResultList({
            subscriberid : props.item.Subscriber_ID,
            patNumber : props.item.PatNum,
            practiceId : props.item.practiceId
        })
            .then((res: any) => {
                setResults(res)
            })
    }, []);

    return (
        <Modal
            open={true}
            onClose={() => props.hideModal(false)}
            centered={false}
            size='tiny'
            closeIcon
        >
            <Header content='Member Results History'/>
            <Modal.Content>
                <Comment.Group>
                    {results && results.map((item: any) => {
                        return(
                            <Comment>
                                <Comment.Content>
                                    <Comment.Author>{item.user ? item.user.firstname + ' ' + item.user.lastname :''}</Comment.Author>
                                    <Comment.Metadata>
                                        <div>{moment(item.createdAt).fromNow()}{}</div>
                                    </Comment.Metadata>
                                    <Comment.Text>Changed result as {item.Result}</Comment.Text>
                                </Comment.Content>
                            </Comment>
                        )
                    })}

                </Comment.Group>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={() => props.hideModal(false)}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ResultsModal