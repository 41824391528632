import { App } from "../../model";
import { ActionType } from "../../model/actionType";
import {errorHandler} from "./auth";

export const SidebarOpen = (flag: App) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		dispatch({ type: ActionType.SIDEBAR_OPEN, payload: { leftSidebarOpen: flag } });
	} catch (err) {
		return dispatch({ type: ActionType.SIDEBAR_OPEN, payload: { leftSidebarOpen: false } });
	}
};

export const Loader = (flag: App) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		dispatch({ type: ActionType.LOADER, payload: { loader: flag } });
	} catch (err) {
		return dispatch({ type: ActionType.LOADER, payload: { loader: false } });
	}
};

export const sendFeedback = (feedback : any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("report/sendFeedback", feedback);
		dispatch({ type: ActionType.SEND_FEEDBACK, payload: resp });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const saveUsage = (usage : any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const resp =  await api.post("report/saveUsage", usage);
		dispatch({ type: ActionType.SAVE_USAGE, payload: resp });
		return resp;
	} catch (err) {
		return dispatch(errorHandler(err));
	}
};

export const saveSetup = (data : any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("pmd/setDefault", data);
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const getDefaultSetup = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const resp=await api.get('pmd/getSetup', {}, {}, getState().auth.accessToken)
		dispatch({ type: ActionType.DEFAULT_SETUP, payload: { defaultSetup: resp } });
		return resp;
	} catch (err) {
		return dispatch({ type: ActionType.DEFAULT_SETUP, payload: { defaultSetup: null } });
	}

}


