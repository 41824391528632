/**
 * Is valid array
 * @param arr
 */
 export const validArr = (arr: any): boolean => arr && Array.isArray(arr) && !!(arr.length);


/**
 * object is valid
 * @param obj
 */
export const validObj = (obj: object | undefined) => !!(obj && Object.keys(obj).length);
