import React, { useState } from "react";
import { Menu, Dropdown, Image } from 'semantic-ui-react';
import FeedbackModal from "./FeedbackModal";
import { Account } from "../model/user";
import moment from "moment";
import { useActions } from "../redux/actions";
import * as AppActions from "../redux/actions/app";
import * as UserSetupActions from "../redux/actions/userSettings";
import DefaultPracticeModal from "./DefaultPracticeModal";
import { SetupAppName } from "../enums/userSetupAppNames";
import { SetupFields } from "../enums/userSetupField";

interface Props {
  user: Account,
  defaultSetup: any,
  logout: any;
  listenerFromParent: any,
  handleClick: Function,
  mycdp_uri: string
}


const Header: React.FC<Props> = (props) => {

  const { defaultSetup } = props;
  const memPractice = defaultSetup && defaultSetup.memberPractice;
  const memStatus = defaultSetup && defaultSetup.memberStatus;
  const defaultOptions: any = {
    memberPractice: memPractice,
    memberStatus: memStatus
  }
  const [feedbackModal, setFeedbackPageModal] = useState(false)
  const [activeItem] = useState("")
  const [state, setStates] = useState({} as any);
  const [defaultOpts, setDefaultOptions] = useState(defaultOptions);
  const [defaultPage, setDefault] = useState(false);
  const appActions = useActions(AppActions)
  const userSetupActions=useActions(UserSetupActions)
  const openFeedback = () => {
    setFeedbackPageModal(true)
  }
  const hideFeedbackModal = () => {
    setFeedbackPageModal(false)
  }

  const setStatusFilter = (value: string) => {
    setDefaultOptions({ ...defaultOpts, memberStatus: value ? value : memStatus })
  }

  const onChangeDefaultSetup = (_e: any, data: any) => {
    setDefaultOptions({ ...defaultOpts, memberPractice: data.value ? data.value : memPractice })
  }

  const saveSetup = async() => {
    const {memberPractice, memberStatus} = defaultOpts
    const practiceSetup ={
      appId: SetupAppName.CALL_CENTER,
      field: SetupFields.Practice,
      value: memberPractice ? memberPractice : defaultOptions.memberPractice,
    };
    const statusSetup ={
      appId: SetupAppName.CALL_CENTER,
      field: SetupFields.Status,
      value: memberStatus ? memberStatus : defaultOptions.memberStatus,
    };
    await  userSetupActions.createSetup(practiceSetup);
    await userSetupActions.createSetup(statusSetup);

    setDefault(false);
    window.location.reload()
  }

  const onClickFeedbackPage = () => {
    const feedback = {
      subject: state.subject,
      message: state.message,
      firstName: user.firstname,
      lastName: user.lastname,
      email: user.email,
      practice: state.practice,
      file: state.file,
      dateTime: moment().format('MM/DD/YYYY hh:mm A')
    };
    if (!state.message || !state.subject) {
      alert('Please enter subject and message')
      return;
    }

    appActions.sendFeedback(feedback)
    hideFeedbackModal()
  }

  const onChangeFeedback = (formData: any) => {
    setStates({ ...state, ...formData });
  };

  const { user, logout, listenerFromParent, mycdp_uri } = props;

  let isAdmin = null
  let image = null
  if (user) {
    isAdmin = user.isAdmin || false
    image = <span><Image avatar className='mr-1' src={user.profilePicture} /> <span className="userName">{user.firstname} {user.lastname}</span></span>
  }
  const helpUrl = mycdp_uri ? mycdp_uri.replace('?token', `admin/setup/manager-help?token`) : '#';
  return (
    <>
      <Menu secondary className='mb-0 mt-0'>
        <a onClick={() => openFeedback()} className="btnFeedback">
          <small>FEEDBACK</small>
        </a>
        {feedbackModal &&
          <FeedbackModal hideFeedbackModal={hideFeedbackModal} onChangeFeedback={onChangeFeedback} onClickFeedbackPage={onClickFeedbackPage} />
        }
       {defaultPage &&
        <DefaultPracticeModal
          defaultValues={defaultOptions}
          handleClose={() => setDefault(false)}
          onSaveSetup={saveSetup}
          onChangeDefaultSetup={onChangeDefaultSetup}
          setStatusFilter={setStatusFilter}
          practices={state.practices}
        />
      }
        <Menu.Item className='ml-0 mr-0 mr-lg-4 float-left float-lg-none toggle inverted' icon='content'
          active={activeItem === 'home'}
          onClick={listenerFromParent} />
        <div className="clickable" onClick={(e) => { props.handleClick(e) }}>
          <img src="/images/logoSmall.png" className="logoSmall" alt="Logo"></img>
        </div>
        <Menu.Menu position='right' className='float-right float-lg-none'>
          <Dropdown className="user" trigger={image} icon={null} />
          <Dropdown className='setting inverted item mr-0' icon='ellipsis vertical'>
            <Dropdown.Menu>
              <Dropdown.Item text='Logout' onClick={logout} />
              <Dropdown.Item text='Set Default' onClick={() => setDefault(true)} />
              <Dropdown.Item text='Feedback' onClick={openFeedback} />
              {isAdmin ? <Dropdown.Item>
                <a style={{ color: 'rgba(0, 0, 0, 0.87)' }} href={helpUrl} target='_blank'>Manage Help</a>
              </Dropdown.Item> : ""}
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>

    </>
  )
}

export default Header