import React, {useEffect, useMemo, useState} from "react"
import Template from "./Template"
import {RootState} from "../model";
import {connect} from "react-redux";
import * as MembersActions from "../redux/actions/members";
import {useActions} from "../redux/actions";
import {Button, Confirm, Dropdown, Grid, Icon} from "semantic-ui-react";
import {brands, mcoOptionsList, membersResultFilters} from "../constants";
import * as ReportsActions from "../redux/actions/reports";
import _ from 'lodash';

const MembersStatusReport: React.FC<any> = (props) => {
    const initialFilters: any = {
        practice: [],
        brand: 'all',
        mco: [],
        result: [],
        region:"all",
        brandOrRegion:"brand"
    }
    const initialState: any = {
        column: null,
        data: [],
        total: {},
        direction: null,
    };

    const membersActions = useActions(MembersActions);
    const reportsActions = useActions(ReportsActions);
    const [filters, setFilters] = useState(initialFilters);
    const [state, setStates] = useState(initialState);
    const [confirm, setConfirm] = useState(false);
    const [practiceOptions, setPracticeOptions] = useState([]);
    const [regions, setRegions] = useState([]);


    useEffect(() => {
        membersActions.GetRegions().then((dataReg: any) => {
                const regList: any = [{key:"all",value:"all",text:"All"}]
                dataReg.forEach((item: any) => {
                    regList.push({
                        key: item.id, value: item.id, text: item.name
                    })
                })
                setRegions(regList);
            });
    },[])

    useEffect(() => {
        membersActions.getMembersStatusReport(filters)
            .then((data: any) => {
                const sortByDiffOfExtractedAndTotal = data.rows.length ? data.rows.sort((a: any, b: any) => {
                    return (Number(a.extractedMembers) - a.total) - (Number(b.extractedMembers) - b.total)
                }) : [];

                setStates({
                    ...state,
                    column: 'practice',
                    total : data.totals,
                    data: sortByDiffOfExtractedAndTotal,
                    direction: 'sort ascending',
                });
            });
    }, [filters]);

    useEffect(() => {
        reportsActions.fetchPracticeListByBrand(filters.brand)
            .then((list: { id: any; practice: string; }[]) => {
                const pracList: any = []
                list.forEach((item: any) => {
                    pracList.push({
                        key: item.id, value: item.practiceabbr, text: item.practice
                    })
                })
                setPracticeOptions(pracList);
            });
    }, [filters.brand]);

    useEffect(() => {
        reportsActions.fetchPracticeListByRegion(filters.region)
            .then((list: any) => {
                const pracList: any = []
                list.forEach((item: any) => {
                    pracList.push({
                        key: item.id, value: item.practiceabbr, text: item.practice
                    })
                })
                setPracticeOptions(pracList);
            });
    }, [filters.region]);

    const handleSendReport = () => {
        const { data, total } = state;
        setConfirm(false);
        const newData = [...data, total];
        reportsActions.sendMembersReport(newData);
    }

    const handleSort = (clickedColumn: any) => () => {
        const {column, data, direction} = state;
        if (column !== clickedColumn) {

            setStates({
                ...state,
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'sort ascending',
            });

            return
        }

        setStates({
            ...state,
            data: data.reverse(),
            direction: direction === 'sort ascending' ? 'sort descending' : 'sort ascending',
        })
    };

    const getTableRows = () => {
        const { data, total } = state;
        const rows = new Array();
        const combinedData = [...data, total];
        combinedData && combinedData.map((item: any, index: number) => {
            if (!item.practice) {
                return
            }
            rows.push(
                <tr key={index}>
                    <td>{item.practice}</td>

                    <td>{item.New_Patient_DENTA}</td>
                    <td>{item.New_Patient_MCNA}</td>
                    <td>{item.New_Patient_UHC}</td>

                    <td>{item.Recare_No_Future_Appointment_DENTA}</td>
                    <td>{item.Recare_No_Future_Appointment_MCNA}</td>
                    <td>{item.Recare_No_Future_Appointment_UHC}</td>

                    <td>{item.Recare_Future_Appointment_DENTA}</td>
                    <td>{item.Recare_Future_Appointment_MCNA}</td>
                    <td>{item.Recare_Future_Appointment_UHC}</td>
                    <td className={'color-white'} style={{'background': item.total !== Number(item.extractedMembers) ? 'red' : 'green', 'border' : '2px solid white'}}>{item.total}</td>
                    <td className={'color-white'} style={{'background': item.total !== Number(item.extractedMembers) ? 'red' : 'green', 'border' : '2px solid white'}}>{item.extractedMembers}</td>

                </tr>
            )
        })
        return rows;
    }

    const generateTableHead = (label: string, name: string) => {
        return(
            <th className="cursorPointer" onClick={handleSort(name)}>
                <div>
                    {label} <Icon
                    name={state.column === name ? state.direction : 'sort'}/>
                </div>
            </th>
        )
    }

    const generateTableData = useMemo(() => getTableRows(), [state.data, state.direction]);

    return (
        <Template activeLink="new-patient-origin">
            <Confirm
                open={confirm}
                onCancel={()=> setConfirm(false)}
                onConfirm={()=> handleSendReport()}
                content={'Are you sure you want to send report to yourself?'}
            />
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column columns={16}>
                                <h3 className="left floated">Members Status Report</h3>
                                <div className="topFilters right floated">
                                    <Button
                                        primary
                                        className="mb5"
                                        onClick={() => {
                                            setConfirm(true);
                                        }}
                                    >
                                        Send Report
                                    </Button>
                                    <Dropdown
                                        placeholder="Select Brand"
                                        className='mr10 mb15'
                                        selection={true}
                                        defaultValue={filters.brand}
                                        options={[
                                            ...brands
                                        ]}
                                        onChange={(_event, data) => {
                                            setFilters({...filters,brandOrRegion:"brand", brand: data.value});
                                        }}
                                    />
                                    <Dropdown
                                        placeholder="Select Brand"
                                        className='mr10 mb15'
                                        selection={true}
                                        defaultValue={filters.region}
                                        options={[
                                            ...regions
                                        ]}
                                        onChange={(_event, data) => {
                                            setFilters({...filters, brandOrRegion:"region", region: data.value});
                                        }}
                                    />
                                    <Dropdown
                                        search={true}
                                        className='mr10 mb15'
                                        name="locations"
                                        placeholder="Practice"
                                        selection={true}
                                        multiple={true}
                                        defaultValue={filters.practice}
                                        options={practiceOptions}
                                        onChange={(_event, data) => {
                                            setFilters({...filters, practice: data.value});
                                        }}
                                    />
                                    <Dropdown
                                        placeholder="Select MCO"
                                        className='mr10 mb15'
                                        selection={true}
                                        multiple={true}
                                        defaultValue={filters.mco}
                                        options={[
                                            ...mcoOptionsList
                                        ]}
                                        onChange={(_event, data) => {
                                            setFilters({...filters, mco: data.value});
                                        }}
                                    />
                                    <Dropdown
                                        placeholder="Select Result"
                                        className='mr10 mb15'
                                        selection={true}
                                        multiple={true}
                                        defaultValue={filters.result}
                                        options={[
                                            ...membersResultFilters.sort((a, b) => (a.text > b.text) ? 1 : -1)
                                            ,
                                        ]}
                                        onChange={(_event, data) => {
                                            setFilters({...filters, result: data.value});
                                        }}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="FreezeTable">
                                    <table
                                        className="ui table table-striped celled table-hover customTable unstackable">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th className="text-center border-b-gray bgColor" colSpan={3}>New Patient</th>
                                            <th className="text-center border-b-gray" colSpan={3}>Recare No Future Appointment</th>
                                            <th className="text-center border-b-gray bgColor" colSpan={3}>Recare Future Appointment</th>
                                            <th className="text-center border-b-gray" colSpan={2}>Total</th>
                                        </tr>
                                        <tr>
                                            {generateTableHead('Practice', 'practice')}

                                            {generateTableHead('DENTA', 'New_Patient_DENTA')}
                                            {generateTableHead('MCNA', 'New_Patient_MCNA')}
                                            {generateTableHead('UHC', 'New_Patient_UHC')}

                                            {generateTableHead('DENTA', 'Recare_No_Future_Appointment_DENTA')}
                                            {generateTableHead('MCNA', 'Recare_No_Future_Appointment_MCNA')}
                                            {generateTableHead('UHC', 'Recare_No_Future_Appointment_UHC')}

                                            {generateTableHead('DENTA', 'Recare_Future_Appointment_DENTA')}
                                            {generateTableHead('MCNA', 'Recare_Future_Appointment_MCNA')}
                                            {generateTableHead('UHC', 'Recare_Future_Appointment_UHC')}
                                            {generateTableHead('Members', 'total')}
                                            {generateTableHead('Extracted', 'extractedMembers')}
                                        </tr>

                                        </thead>
                                        <tbody>
                                        {generateTableData}
                                        </tbody>
                                    </table>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </div>
            </div>

        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        loader: state.app.loader,
        report: state.members.membersStatusReport
    };
}

export default connect(mapStateToProps)(MembersStatusReport);
