/* eslint-disable no-restricted-globals */
import React from "react"
import {Sidebar, Menu, Icon, List, Accordion} from 'semantic-ui-react'
import { Link } from "react-router-dom"
import { MenuItem, menusArray, SubMenu } from '../constants';
import {useActions} from '../redux/actions';
import * as appActions from '../redux/actions/app';

interface SideBarMainProps{
    visible:boolean;
    user:any;
    handleClick:Function;
}
const SideBarMain:React.FC<SideBarMainProps>=(props)=>{

const appAction = useActions(appActions);
const closeSideBar = () => {
      appAction.SidebarOpen(false)
    }
const parseLeftMenuPath = (menu: string) => `/${menu.toLowerCase().split(' ').join('-')}`
    // eslint-disable-next-line no-restricted-globals
    let basePath = location.pathname.split('/')[1];
    basePath = basePath.indexOf('-') > -1
      ? basePath.split('-').join(' ')
      : basePath;
    const basePathIndex = basePath
        ? menusArray.findIndex(i => i.name === basePath)
        : -1;
    const panels = menusArray.map((menu:MenuItem, i) => {


      const parentLink = `${parseLeftMenuPath(menu.name)}`;
        // Child Menu
        if (menu.subMenus.length > 0) {
            const childMenus = menu.subMenus.map((subMenu:SubMenu, j:any) => {

              let childPath :string= location.pathname.split('/')[2];
              childPath = childPath && childPath.indexOf('-') > -1
                ? childPath.split('-').join(' ')
                : childPath;
              const childLink = `${parentLink}${parseLeftMenuPath(subMenu.name)}`;
              
              return (
                <List.Item
                  key={j}
                  as='div'
                  className={(childPath?.toLowerCase() === subMenu?.name.toLowerCase()) ? 'active' : ''}

                  onClick={() => {
                    closeSideBar()
                  }}
                > <Link
                to={childLink}
                onClick={closeSideBar}
                className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
            >
              {/* @ts-ignore */}
                  { subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon}  icon`}/> : <Icon name={subMenu.icon}/> }
                  {subMenu.title}
                  </Link>
                </List.Item>
              );
            });
            const angle = 'angle down';
            return {
              key: `panel-${i}`,
              title: { content: (<span className={(basePath.toLowerCase() === menu.name.toLowerCase()) ? 'parentActive' : ''}>
                { menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}/> : <Icon name={menu.icon}/> }
                  {menu.title}
                  <Icon name={angle} />
              </span>) },
              content: { content: (<List>{childMenus}</List>) }
            };
          }
          // Dont show in menu bar if menu hide is set
      if(menu.hide) {
        return {key: `panel-${i}`};
      }

      return {
        key: `panel-${i}`,
        content: (<Link
          to={ parentLink }
          className={(basePath.toLowerCase() === menu.name.toLowerCase()) ? 'active' : ''}
          onClick={() =>closeSideBar() }
        >
          { menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}/> : <Icon name={menu.icon}/> }
          { menu.title }
        </Link>)
      };
     
    })
    return (
        <Sidebar
        as={Menu}
        animation='push'
        width='thin'
        visible={props.visible}
        icon='labeled'
        vertical
        inverted
      >
        <div className="clickable" onClick={(e)=>{props.handleClick(e)}}>
        <img src="/images/logoMain.png" className="logo align-self-center" alt="Logo"/>
        </div>
        <Accordion className="leftMenu" panels={ panels } defaultActiveIndex={ basePathIndex } />
      </Sidebar>
    )
    
}

export default SideBarMain