import { MemberPayload } from "../../model";
import {ActionType} from "../../model/actionType"
import {errorHandler} from "./auth"
import {cronsListUrl, dentistListUrl, openIssuesListUrl, setCookie} from "../../constants";


export const getMembers=
// (pageNum:number,company:string,currentDentistFilter:string,currentResultFilter:string,currentStatusFilter:string,currentOrderBy:string)=>async(dispatch: Function, getState: Function, api: any)=>{
    (payload:MemberPayload)=>async(dispatch: Function, getState: Function, api: any)=>{
    const {currentPage,
        currentCompany,
        currentDentistFilter,
        currentResultFilter,
        currentStatusFilter,
        currentMcoFilter,
        currentOrderBy,
        currentPractice,
        currColumn,
        currentSearch
    }=payload
    const url=`Members/getMembersTableData?pageNum=${currentPage}&company=${currentCompany}&search=${currentSearch}&dentistFirstName=${currentDentistFilter?currentDentistFilter:""}&result=${currentResultFilter}&status=${currentStatusFilter}&orderByColumn=${currentOrderBy}&practice=${currentPractice}&sameColumn=${currColumn}&mco=${currentMcoFilter}`
    dispatch({ type: ActionType.LOADER, payload: true });
    try{
    const resp=await api.get(url)
    dispatch({
        type:ActionType.LOAD_MEMBERS,
        payload:{
            members_count:resp.members_count,
            pageNum:resp.pageNum,
            members:resp.members,
            total_count: resp.total_count
        }
    })
    setCookie('memberFilters', JSON.stringify(payload), 30);
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp
    }catch(err){
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getDentistList=()=>async(dispatch: Function, getState: Function, api: any)=>{
    dispatch({ type: ActionType.LOADER, payload: true });
    try{
    const resp=await api.get(dentistListUrl, {}, {}, getState().auth.accessToken)
    dispatch({
        type:ActionType.LOAD_DENTIST_LIST,
        payload:{
            
            ...resp
        }
    })
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp
    }catch(err){
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getOpenIssues = (applicationList:string[]) => async(dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });

    try{
        const resp = await api.post(openIssuesListUrl, {applicationList:applicationList});

        dispatch({
            type:ActionType.LOAD_OPEN_ISSUES_LIST,
            payload:{
                ...resp
            }
        })
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;

    }catch(err){

        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const SubmitNotes=
(payload:any)=>async(dispatch:Function,getState:Function,api:any)=>{
    const url=`Members/saveNotes`
    dispatch({type:ActionType.LOADER,payload:true})
    try{
        const resp=await api.post(url,payload)
        dispatch({
            type:ActionType.SAVE_NOTES,
            payload:{
                
            }
        })
        dispatch({type:ActionType.LOADER,payload:false})
        return resp
    }catch(err){
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const SaveResult=
(payload:any)=>async(dispatch:Function,getState:Function,api:any)=>{
    const url=`Members/saveResult`
    dispatch({type:ActionType.LOADER,payload:true})
    try{
        const resp=await api.post(url,payload)
        dispatch({
            type:ActionType.SAVE_RESULT,
            payload:{
                
            }
        })
        dispatch({type:ActionType.LOADER,payload:false})
        return resp
    }catch(err){
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const GetPractices= (brand: string) =>async(dispatch:Function,getState:Function,api:any)=>{
    const url=`Members/practicesList/`+brand
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp=await api.get(url)
        dispatch({
            type:ActionType.LOAD_PRACTICES,
            payload:{
                practicesList:resp.practicesList
            }
        })
        dispatch({type:ActionType.LOADER,payload:false})

        return resp
    }catch(err){
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}
export const GetRegions= () =>async(dispatch:Function,getState:Function,api:any)=>{
    const url=`Members/regionsList`
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp=await api.get(url)
        dispatch({
            type:ActionType.LOAD_REGIONS,
            payload:{
                regionsList:resp.regionsList
            }
        })
        dispatch({type:ActionType.LOADER,payload:false})

        return resp.regionsList??[]
    }catch(err){
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}
export const getNotesList = (params: any) => async(dispatch:Function,getState:Function,api:any)=>{
    const url=`Members/getNotes/`+JSON.stringify(params)
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp=await api.get(url)
        dispatch({type:ActionType.LOADER,payload:false})
        return resp
    }catch(err){
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export  const getResultList = (params: any) => async(dispatch:Function,getState:Function,api:any)=>{
    const url=`Members/getResults/`+JSON.stringify(params)
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp=await api.get(url)
        dispatch({type:ActionType.LOADER,payload:false})
        return resp
    }catch(err){
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}
export const getCronList = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp = await api.get(cronsListUrl, {}, {}, getState().auth.accessToken);
		dispatch({ type: ActionType.LOAD_CRONS_LIST, payload: { cronList: resp } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const getMembersStatusReport = (params : any) => async (dispatch: Function, getState : Function, api: any)=> {
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const param = JSON.stringify(params);
        const url = `Members/members-status-report?params=${param}`;
        const resp = await api.get(url);
        dispatch({ type: ActionType.LOAD_MEMBERS_STATUS_REPORT, payload:  resp  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch (error) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(error));
    }
}