import React, {ChangeEvent, useEffect, useState} from "react";
import {Button, Modal, Input, Header, TextArea, Form, Dropdown} from "semantic-ui-react";
import {useActions} from "../redux/actions";
import * as practiceActions from "../redux/actions/practice";


const FeedbackModal: React.FC<any> = (props) => {
    const initialState = {
        practiceOptions: [],
        formData: {}
    };
    const [state, setStates] = useState(initialState);
    const practiceAction = useActions(practiceActions);
    useEffect(() => {
        practiceAction.getPractices().then(loadPractices)
    }, []);

    const loadPractices = (practices: any[]) => {
        let practiceOptions: any = practices.map((practice, key) => ({
            text: practice.practice,
            value: practice.practice,
            key
        }));
        practiceOptions = [
            {text: 'Support', value: 'Support', key: practiceOptions.length + 1},
            ...practiceOptions
        ];
        setStates({...state, practiceOptions})
    }
    const onChange = (e: any, data: any) => {
        let formData: any = state.formData;
        formData[data.name] = data.value;
        setStates({...state, formData});
        props.onChangeFeedback(formData);
    }

    const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        if (!e.target || !e.target.files) return;
        const value = await fileToBase64(e.target.files[0]);
        onChange(null, {name: 'file', value});
    }

    const fileToBase64 = async (file: any) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (e) => reject(e)
        })

    return (
        <Modal
            open={true}
            onClose={props.hideFeedbackModal}
            centered={false}
            size='tiny'
            closeIcon
        >
            <Header content='Feedback'/>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Practice/Support</label>
                        <Dropdown
                            search={true}
                            name="practice"
                            placeholder="Practice"
                            selection={true}
                            options={state.practiceOptions}
                            onChange={onChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Subject</label>
                        <Input fluid={true} name='subject' placeholder='Subject' onChange={onChange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Message</label>
                        <TextArea rows={6} fluid={true} name='message' onChange={onChange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Attachments</label>
                        <input
                            name='file'
                            type="file"
                            onChange={onFileChange}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={props.hideFeedbackModal}>
                    Cancel
                </Button>
                <Button className='ui primary button' onClick={props.onClickFeedbackPage}>
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default FeedbackModal